.title-modal-pet {
  fonm-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  /* Black */
  color: #212121;
}

.size-img-modal {
  width: 127px;
  height: 127px;
  border-radius: 127px;
}
