.conten-seguro {
  height: 100%;
  width: 100%;
  background: #e5e5e5;
  border: 1px dotted #e5e5e5;
}

.container-gracias {
  position: relative;
}

.text-descripcion-cont {
  width: 100% !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* White */

  color: #ffffff;
}

/* pantalla grande  */
@media only screen and (min-width: 1440px) and (max-width: 10000px) {
  .card-gracias {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
    gap: 40px;

    width: 1140px;
    height: auto;

    /* White */

    background: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .felicidad {
    width: 756px;
    height: 64px;
  }

  .descarga {
    width: 587px;
    height: 72px;
  }

  .descripcion-plan {
    display: flex;
    padding: 24px 58px;

    width: 532px;
    height: auto !important;

    /* Azul Inter */

    background: #039ecc;
    border-radius: 12px 12px 0px 0px;
  }

  .div-poliza {
    width: 610px;
    height: auto !important;
  }

  .div-detail {
    display: flex;
    width: 647px;
    height: 202px;

    /* azul 5% */

    background: #f2fafc;
    border-radius: 0px 0px 12px 12px;
  }

  .telefonazo {
    width: 587px;
    height: 48px;
  }

  .div-button {
    width: 587px !important;
  }

  .text-atencion {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-descarga {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-dudas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-felicidad {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    /* or 114% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .div-credential-pet {
    width: 350px;
    height: auto;
  }

  .data-pet {
    width: 530px;
    height: auto;
    margin-top: 20px;
    margin-left: 62px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .card-gracias {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
    gap: 40px;

    width: 1140px;
    height: auto;

    /* White */

    background: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .felicidad {
    width: 756px;
    height: 64px;
  }

  .descarga {
    width: 587px;
    height: 72px;
  }

  .descripcion-plan {
    display: flex;
    padding: 24px 58px;

    width: 532px;
    height: auto !important;

    /* Azul Inter */

    background: #039ecc;
    border-radius: 12px 12px 0px 0px;
  }

  .div-poliza {
    width: 610px;
    height: auto !important;
  }

  .div-detail {
    display: flex;
    width: 647px;
    height: 202px;

    /* azul 5% */

    background: #f2fafc;
    border-radius: 0px 0px 12px 12px;
  }

  .telefonazo {
    width: 587px;
    height: 48px;
  }

  .div-button {
    width: 587px !important;
  }

  .text-atencion {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-descarga {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-dudas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-felicidad {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    /* or 114% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .div-credential-pet {
    width: 350px;
    height: auto;
  }

  .data-pet {
    width: 530px;
    height: auto;
    margin-top: 20px;
    margin-left: 62px;
  }
}

/* Laptop */
@media only screen and (min-width: 821px) and (max-width: 1024px) {
  .card-gracias {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
    gap: 40px;

    width: 754x;
    height: auto;

    /* White */

    background: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .felicidad {
    width: 629px;
    height: 96px;
  }

  .descarga {
    width: 587px;
    height: 72px;
  }

  .descripcion-plan {
    display: flex;
    padding: 24px 58px;

    width: 532px;
    height: auto !important;

    /* Azul Inter */

    background: #039ecc;
    border-radius: 12px 12px 0px 0px;
  }

  .div-poliza {
    width: 610px;
    height: auto !important;
  }

  .data-pet {
    width: 530px;
    height: auto;
    margin-top: 20px;
    margin-left: 62px;
  }

  .div-detail {
    display: flex;
    width: 647px;
    height: 202px;

    /* azul 5% */

    background: #f2fafc;
    border-radius: 0px 0px 12px 12px;
  }

  .telefonazo {
    width: 587px;
    height: 48px;
  }

  .div-button {
    width: 587px !important;
  }
  .text-atencion {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-descarga {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-dudas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-felicidad {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    /* or 114% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .div-credential-pet {
    width: 350px;
    height: auto;
  }
}
/* Tablets lager */
@media only screen and (min-width: 801px) and (max-width: 821px) {
  .card-gracias {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
    gap: 40px;

    width: 754x;
    height: auto;

    /* White */

    background: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .felicidad {
    width: 629px;
    height: 96px;
  }

  .descarga {
    width: 587px;
    height: 72px;
  }

  .descripcion-plan {
    display: flex;
    padding: 24px 58px;

    width: 532px;
    height: auto !important;

    /* Azul Inter */

    background: #039ecc;
    border-radius: 12px 12px 0px 0px;
  }

  .div-poliza {
    width: 610px;
    height: auto !important;
  }

  .data-pet {
    width: 530px;
    height: auto;
    margin-top: 20px;
    margin-left: 62px;
  }

  .div-detail {
    display: flex;
    width: 647px;
    height: 202px;

    /* azul 5% */

    background: #f2fafc;
    border-radius: 0px 0px 12px 12px;
  }

  .telefonazo {
    width: 587px;
    height: 48px;
  }

  .div-button {
    width: 587px !important;
  }
  .text-atencion {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-descarga {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-dudas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-felicidad {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    /* or 114% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .div-credential-pet {
    width: 350px;
    height: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 800px) {
  .card-gracias {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
    gap: 40px;

    width: 754x;
    height: auto;

    /* White */

    background: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .felicidad {
    width: 629px;
    height: 96px;
  }

  .descarga {
    width: 587px;
    height: 72px;
  }

  .descripcion-plan {
    display: flex;
    padding: 24px 58px;

    width: 532px;
    height: auto !important;
    /* Azul Inter */

    background: #039ecc;
    border-radius: 12px 12px 0px 0px;
  }

  .div-poliza {
    width: 610px;
    height: auto !important;
  }

  .data-pet {
    width: 530px;
    height: auto;
    margin-top: 20px;
    margin-left: 62px;
  }

  .div-detail {
    display: flex;
    width: 647px;
    height: 202px;

    /* azul 5% */

    background: #f2fafc;
    border-radius: 0px 0px 12px 12px;
  }

  .telefonazo {
    width: 587px;
    height: 48px;
  }

  .div-button {
    width: 587px !important;
  }
  .text-atencion {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-descarga {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-dudas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-felicidad {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    /* or 114% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .div-credential-pet {
    width: 350px;
    height: auto;
  }
}

/* Teléfonos */
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .card-gracias {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
    gap: 40px;

    width: 357 !important;
    height: auto !important;

    /* White */

    background: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .felicidad {
    width: 295px;
    height: 96px;
  }

  .descarga {
    width: 274px;
    height: 100px;
  }

  .descripcion-plan {
    display: flex;
    width: 316px !important;
    height: auto !important;

    /* Azul Inter */

    background: #039ecc;
    border-radius: 12px 12px 0px 0px;
  }

  .div-detail {
    display: flex;
    width: 316px;
    height: 393px;

    /* azul 5% */

    background: #f2fafc;
    border-radius: 0px 0px 12px 12px;
  }

  .telefonazo {
    width: 298px;
    height: 60px;
  }

  .div-button {
    width: 298px !important;
  }

  .ayuda {
    width: 278px;
    height: 24px;
  }

  .text-atencion {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-descarga {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-dudas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .text-felicidad {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .div-poliza {
    width: 300px !important;
    height: auto !important;
    padding: 0px 10px 0px 10px;
  }
  .data-pet {
    /* width: 300px; */
    /* width: auto !important;
    height: auto !important;
    margin-top: 20px;
    margin-left: 2px; */
    width: auto !important;
    height: auto !important;
    background: #f2fafc;
    padding: 1em;
  }
  .div-credential-pet {
    width: 272px;
    height: auto;
  }

  .text-ayuda {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px !important;
    line-height: 32px;
    text-align: center;

    /* Black */

    color: #212121;
  }
}

.text-inter {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  /* or 114% */

  text-align: center;
  letter-spacing: 0.5px;

  /* Black */

  color: #039ecc;
}

.text-descripcion {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* White */

  color: #ffffff;
}

.telefonos {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 28px;
  line-height: 32px;
  /* identical to box height, or 114% */

  text-align: center;
  letter-spacing: 0.5px;

  /* Black */

  color: #212121;
}

.text-ayuda {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  text-align: center;

  /* Black */

  color: #212121;
}
