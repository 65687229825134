.content-add {
  height: auto;
  width: 100%;
  background-color: #f4f4f4 !important;
}

.container-add-pets {
  padding-top: 1%;
  position: relative;
}

.card-content-form-add {
  /*width: 801px;*/
  height: auto;
  margin-bottom: 24px;
  /* White */
  background: white;
  border-radius: 8px;
  padding-bottom: 24px;
  left: 150px;
  /* White */
}

/* .grid-card-detail-insurance {
} */

.card-detail-plan-add {
  flex-direction: column;
  padding: 26px;
  gap: 10px;
  height: auto !important;
  background: #fff;
  border-radius: 10px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.card-detail-plan-add-dos {
  flex-direction: column;
  padding: 25px;
  gap: 10px;
  width: 315px;
  height: auto !important;
  background: #f2fafc;
  border-radius: 10px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.card-folio-add {
  width: 315px !important;
}

.text-title-folio {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  display: flex;
  align-items: center;

  /* Black */

  color: #212121;

  /* Inside auto layout */

  flex: none;
  order: 0;
}

.card-img-desk-tablet {
}

.card-img-mobile {
}

/* pantalla grande  */
@media only screen and (min-width: 1441px) and (max-width: 10000px) {
  .content-add {
    flex-direction: column;
    /* padding: 15px 0px 0px 250px;*/
    padding: 0px 0px 0px 0px;
    height: auto !important;
    width: auto !important;
    background-color: #f4f4f4 !important;
  }

  .card-content-form-add {
    width: 801px !important;
    margin-left: 200px;
  }
  .card-detail-plan-add {
    width: 315px !important;
  }
  .card-folio-add {
    width: 365px !important;
  }
  .card-img-mobile {
    display: none !important;
  }

  .title-pets {
    width: 760px !important;
  }
}

/* Escritorio grande */
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .content-add {
    flex-direction: column;
    /* padding: 15px 0px 0px 50px; */
    padding: 0px 0px 0px 50px;
    height: auto !important;
    width: auto !important;
    background-color: #f4f4f4 !important;
  }

  .card-content-form-add {
    width: 720px !important;
    margin-left: 60px !important;
  }
  .card-detail-plan-add {
    width: 265px !important;
  }
  .card-folio-add {
    width: 300px !important;
    padding: 10px 3px !important;
  }
  .card-img-mobile {
    display: none !important;
  }

  .title-pets {
    width: 710px !important;
    margin-left: 18px !important;
  }
  .card-detail-plan-add-dos {
    width: 270px !important;
    height: auto !important;
    background: #f2fafc;
  }
}

/* Laptop */
@media only screen and (min-width: 1023px) and (max-width: 1024px) {
  .content-add {
    height: auto !important;
    width: 100% !important;
    background-color: #f4f4f4 !important;
  }
  .card-content-form-add {
    width: 580px !important;
    margin-left: 20px !important;
    /* margin-left: 60px!important;*/
  }

  .card-detail-plan-add {
    /*width: 265px !important;*/
    width: 263px !important;
    height: auto !important;
    background-color: #ffffff !important;
    /* margin-left: 30px!important;*/
  }
  .card-folio-add {
    width: 315px !important;
    /* margin-left: 30px!important;*/
  }
  .card-img-mobile {
    display: none !important;
  }
  .title-pets {
    width: 600px !important;
  }
  .card-detail-plan-add-dos {
    width: 265px !important;
    height: auto !important;
    background: #f2fafc;
  }
}

/* tablet LLL */
@media only screen and (min-width: 900px) and (max-width: 1022px) {
  .content-add {
    flex-direction: column;
    /* padding: 15px 0px 0px 50px;*/
    padding: 0px 0px 0px 60px;
    height: auto !important;
    width: 100% !important;
    justify-content: center !important;
    align-content: center !important;
    background-color: #f4f4f4 !important;
  }
  .card-content-form-add {
    width: 700px !important;
    margin-left: 0 !important;
    /* margin-left: 60px!important;*/
  }

  .card-detail-plan-add {
    /*width: 265px !important;*/
    width: 580px !important;
    height: auto !important;
    background-color: #ffffff !important;
    /* margin-left: 30px!important;*/
  }
  .card-folio-add {
    width: 630px !important;
    /* margin-left: 30px!important;*/
  }
  .card-img-mobile {
    display: none !important;
  }
  .title-pets {
    width: 600px !important;
  }
  .card-detail-plan-add-dos {
    width: 650px !important;
    height: auto !important;
    background: #f2fafc;
  }
}

/* tablet LL */
@media only screen and (min-width: 822px) and (max-width: 899px) {
  .content-add {
    flex-direction: column;
    /* padding: 15px 0px 0px 50px;*/
    padding: 0px 0px 0px 40px;
    height: auto !important;
    width: 100% !important;
    justify-content: center !important;
    align-content: center !important;
    background-color: #f4f4f4 !important;
  }
  .card-content-form-add {
    width: 700px !important;
    margin-left: 0 !important;
    /* margin-left: 60px!important;*/
  }

  .card-detail-plan-add {
    /*width: 265px !important;*/
    width: 580px !important;
    height: auto !important;
    background-color: #ffffff !important;
    /* margin-left: 30px!important;*/
  }
  .card-folio-add {
    width: 630px !important;
    /* margin-left: 30px!important;*/
  }
  .card-img-mobile {
    display: none !important;
  }
  .title-pets {
    width: 600px !important;
  }
  .card-detail-plan-add-dos {
    width: 650px !important;
    height: auto !important;
    background: #f2fafc;
  }
}

/* Tablets lager */
@media only screen and (min-width: 801px) and (max-width: 821px) {
  .grid-card-detail-insurance {
    justify-content: left !important;
  }
  .card-content-form-add {
    width: auto !important;
  }
  .card-detail-plan-add {
    width: 509px !important;
    background-color: #fff !important;
    align-items: flex-end !important;
    height: auto !important;
  }
  .card-folio-add {
    width: 559px !important;
  }
  .card-img-mobile {
    display: none !important;
  }

  .title-pets {
    width: 707px !important;
  }
  .card-detail-plan-add-dos {
    width: 690px !important;
    height: auto !important;
    background: #f2fafc;
    padding: 25px;
    gap: 10px;
    border-radius: 10px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

/* Tablets y phablets */
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .grid-card-detail-insurance {
    justify-content: left !important;
  }
  .card-content-form-add {
    width: auto !important;
  }
  .card-detail-plan-add {
    width: 509px !important;
    height: auto !important;
    background-color: #fff !important;
    justify-content: center !important;
  }
  .card-folio-add {
    width: 559px !important;
  }
  .card-img-mobile {
    display: none !important;
  }
  .title-pets {
    width: 650px !important;
    margin-left: 40px !important;
  }
  .card-detail-plan-add-dos {
    width: 668px !important;
    height: auto !important;
    background: #f2fafc;
    padding: 25px;
    gap: 10px;
    border-radius: 10px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

/* Teléfonos */
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .grid-card-detail-insurance {
  }
  .card-content-form-add {
    width: auto !important;
  }
  .card-detail-plan-add {
    width: auto !important;
    height: auto !important;
  }
  .card-folio-add {
    width: 100% !important;
  }
  .card-img-desk-tablet {
    display: none !important;
  }
  .title-pets {
    width: 348px !important;
  }
  .card-detail-plan-add-dos {
    width: auto !important;
    height: auto !important;
    background: #f2fafc;
    padding: 1em;
    border-radius: 10px;
  }
}

.edit-button {
  color: #c0c0c0 !important;
  position: absolute;
  padding: 3px;
}

.edit-button:hover {
  cursor: pointer !important;
  color: #039ecc !important;
  background-color: rgba(197, 197, 197, 0.41) !important;
  border-radius: 100% !important;
  padding: 3px;
  position: absolute;
}

.linea-amarilla {
  position: relative;
}

.linea-amarilla img {
  left: 0;
  top: 0;
  position: absolute;
}

.linea-verde {
  position: relative;
}

.linea-verde img {
  left: 0;
  top: 0;
  position: absolute;
}

.success img {
  left: 0;
  top: 0;
  position: relative;
}

.mX-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.text-queremos {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  margin-left: 17px;
  /* identical to box height, or 114% */

  letter-spacing: 0.5px;

  /* Black */

  color: #212121;
}

.text-asi {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 17px;
  /* identical to box height, or 150% */

  /* Black */

  color: #212121;
}

.numero {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 71px;
  /* identical to box height */

  /* Black */

  color: #212121;
}

.text-disabled {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  margin-left: 17px;
  text-transform: none;
  /* identical to box height, or 114% */

  letter-spacing: 0.5px;

  /* Black 25% */

  color: #c7c7c7;
}

.text-asi-disabled {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 17px;
  text-transform: none;
  /* identical to box height, or 150% */

  /* Black 25% */

  color: #c7c7c7;
}

.num-disabled {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 71px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.15);
}
