.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mX-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.circulo-gde {
  width: 506px;
  height: 506px;
  border-radius: 50%;
  border: 2px solid #ffd60e;
  background: transparent;
  position: absolute;
  right: -250px;
  top: 140px;
  overflow: hidden;
}

.circulo {
  width: 368px;
  height: 368px;
  border-radius: 50%;
  border: 2px solid #ffd60e;
  background: transparent;
  position: absolute;
  right: -185px;
  top: 210px;
  overflow: hidden;
}

.circulo-beneficios-gde {
  width: 466px;
  height: 466px;
  border-radius: 50%;
  border: 2px solid #ffd60e;
  background: transparent;
  position: absolute;
  left: -327px;
  top: 1800px;
  overflow: hidden;
}

.circulo-beneficios {
  width: 368px;
  height: 368px;
  border-radius: 50%;
  border: 2px solid #ffd60e;
  background: transparent;
  position: absolute;
  left: -260px;
  top: 1850px;
  overflow: hidden;
}

.circulo-preguntas2-gde {
  width: 466px;
  height: 466px;
  border-radius: 50%;
  border: 2px solid #ffd60e;
  background: transparent;
  position: absolute;
  right: -200px;
  top: 880px;
  overflow: hidden;
}

.circulo-preguntas2 {
  width: 368px;
  height: 368px;
  border-radius: 50%;
  border: 2px solid #ffd60e;
  background: transparent;
  position: absolute;
  right: -160px;
  top: 930px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .circulo-gde,
  .circulo,
  .circulo-beneficios,
  .circulo-beneficios-gde,
  .circulo-preguntas-gde,
  .circulo-preguntas,
  .circulo-preguntas2-gde,
  .circulo-preguntas2 {
    display: none !important;
  }
}

.case-none {
  text-transform: none !important;
}

.MuiFormHelperText-root.Mui-error {
  color: #cd0505;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

img[src="“https://ad.doubleclick.net/ddm/activity/src=10089018;type=invmedia;cat=mx_in000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?”"]{
  display: none;
}
