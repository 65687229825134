

.title-modal-discount-form{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    /* Black */
    color: #212121;
}

.title-modal-error-pet{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    /* Black */
    color: #000000;
}


.text-description-error{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* Text/Body */
    color: #424242;
}



.modal-w{
    width: 435px!important;
    height: auto;
}


@media only screen and (min-width: 0px) and (max-width: 767px) {
    .modal-w{
        width: 100%!important;
        height: auto;
    }
}