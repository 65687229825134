.div-preguntas {
  width: 100%;
  height: auto;
  background: #e5e5e5;
}

.back-button {
  color: #039ecc !important;
}

.div-img {
  width: 461px;
  height: auto;
  /* border: 1px solid #039ecc; */
}

/*checarfco*/

.tabulador {
  box-sizing: border-box;
  width: 290px !important;
  height: auto;
  left: 0px;
  top: 256px;

  /* White */

  background: #ffffff;
  /* Azul Inter */

  border: 1px solid #039ecc;
  border-radius: 6px;
}

.regresan {
  width: 140px !important;
  height: auto;
  left: 7px;
  top: 282px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  text-align: center;

  /* Black */

  color: #212121;
}

.regresan1 {
  width: 151px !important;
  height: auto;
  left: 7px;
  top: 282px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  text-align: center;

  /* Black */

  color: #212121;
}

.regresan2 {
  width: 127px;
  height: 79px;
  left: 154px;
  top: 256px;

  text-align: center;

  /* Azul 25% */

  background: #c0e6f2;
  opacity: 0.5;
}

.precio {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  /* identical to box height, or 141% */

  text-align: center;

  /* Black */

  color: #212121;
}
.vacuna {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height, or 240% */

  text-align: center;

  /* Black */

  color: #212121;
}

.baño-img {
  width: 103px;
  height: 111px;
  text-align: center;
}

.baño-descripcion {
  width: 170px;
  height: 111px;
  left: 120px;
  top: 359px;

  /* Azul 25% */
  background: #c0e6f2;
  opacity: 0.5;
}

.baño-titulo {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* Black */

  color: #212121;
}

.baño-text-descripcion {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;

  /* identical to box height, or 240% */

  text-transform: uppercase;

  /* Black */

  color: #212121;
}

.costo-baño {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  /* identical to box height, or 141% */

  text-align: right;

  /* Black */

  color: #212121;
}

.div-costo {
  width: 44px;
  height: auto;
}

.div-kg {
  width: 90px;
  height: auto;
}

.div-michis-lomito {
  box-sizing: border-box;
  width: 275px;
  height: 513px;
  left: 0px;
  top: 0px;

  /* White */

  background: #ffffff;
  /* Azul Inter */

  border: 1px solid #039ecc;
  border-radius: 6px;
}

.div-img-gato {
  width: 66.56px;
  height: 95.09px;
}

.body-text-gato {
  width: 257px;
  height: 230px;
}

.titulo-michi {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
}

.text-michi {
  font-style: normal;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
}

.div-img-perro {
  width: 113.88px;
  height: 95.49px;
}

.body-text-perro {
  width: 257px;
  height: 360px;
}

.div-inter {
  width: 192px;
  height: 80px;
}

.text-inter {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
}

.text-inter-preguntas {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
}

/* pantalla grande  */
@media only screen and (min-width: 1441px) and (max-width: 10000px) {
  .div-titulo {
    width: 461px !important;
    height: auto;
    /* border: 1px solid olivedrab; */
  }

  .titulo-preguntas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    align-items: center;
    text-align: center;

    /* Black */

    color: #212121;
  }
  .circulo-preguntas-gde {
    width: 666px;
    height: 666px;
    border-radius: 50%;
    border: 2px solid #ffd60e;
    background: transparent;
    position: absolute;
    left: -180px;
    top: -200px;
    overflow: hidden;
    clip-path: inset(30% 0 0 0);
  }

  .circulo-preguntas {
    width: 568px;
    height: 568px;
    border-radius: 50%;
    border: 2px solid #ffd60e;
    background: transparent;
    position: absolute;
    left: -130px;
    top: -150px;
    overflow: hidden;
    clip-path: inset(27% 0 0 0);
  }
  .pregunta2 {
    max-width: 98% !important;
    height: auto !important;
  }

  .div-pregunta2-gmm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    gap: 10px;

    width: 100% !important;
    height: 35px;

    /* Azul 50% */

    background: #77cae3;
    /* Azul Inter */

    border-radius: 8px 8px 0px 0px;
  }

  .div-desc-michis-lomito {
    width: 574px !important;
    height: 60px !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-top: 3px;
    margin-left: 20px;

    color: #000000;
  }

  .div-img-aseguradora {
    width: 102px;
    height: 80px;
    /* border: 1px solid red; */
    margin-top: 3px;
  }

  .div-aseguradora {
    max-width: 775px;
    height: auto !important;
    margin-bottom: 10px;
    margin-left: 22px;
    /* border: 1px solid red; */
  }

  .py {
    padding-top: 40px !important;
    /* padding-right: 24px; */
  }

  .mx {
    margin-left: 97px !important;
    margin-right: 97px !important;
  }
}

/* Escritorio grande */
@media only screen and (min-width: 1026px) and (max-width: 1440px) {
  .div-titulo {
    width: 461px !important;
    height: auto;
    /* border: 1px solid olivedrab; */
  }

  .titulo-preguntas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    align-items: center;
    text-align: center;

    /* Black */

    color: #212121;
  }
  .circulo-preguntas-gde {
    width: 566px;
    height: 566px;
    border-radius: 50%;
    border: 2px solid #ffd60e;
    background: transparent;
    position: absolute;
    left: -180px;
    top: -200px;
    overflow: hidden;
    clip-path: inset(35% 0 0 0);
  }

  .circulo-preguntas {
    width: 468px;
    height: 468px;
    border-radius: 50%;
    border: 2px solid #ffd60e;
    background: transparent;
    position: absolute;
    left: -130px;
    top: -150px;
    overflow: hidden;
    clip-path: inset(32% 0 0 0);
  }

  .pregunta2 {
    max-width: 98% !important;
    height: auto !important;
  }

  .div-gmm {
    /* max-width: 100%; */
    width: 370px !important;
    height: auto !important;

    /* Black 5% */

    background: #f4f4f4;
    border-radius: 8px 8px 0px 0px;
  }

  .div-pregunta2-gmm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    gap: 10px;
    width: 100% !important;
    height: 35px;
    /* Azul 50% */
    background: #77cae3;
    /* Azul Inter */

    border-radius: 8px 8px 0px 0px;
  }

  .div-desc-michis-lomito {
    width: 574px !important;
    height: 60px !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-top: 3px;
    margin-left: 20px;
    color: #000000;
  }

  .div-img-aseguradora {
    width: 102px;
    height: 80px;
    /* border: 1px solid red; */
    margin-top: 3px;
  }

  .div-aseguradora {
    max-width: 775px;
    height: auto !important;
    margin-bottom: 10px;
    margin-left: 22px;
    /* border: 1px solid red; */
  }

  .py {
    padding-top: 40px !important;
    /* padding-right: 24px; */
  }

  .mx {
    margin-left: 97px !important;
    margin-right: 97px !important;
  }
}

/* Laptop */
@media only screen and (min-width: 1011px) and (max-width: 1025px) {
  .div-titulo {
    width: 461px !important;
    height: auto;
    /* border: 1px solid olivedrab; */
  }

  .titulo-preguntas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    align-items: center;
    text-align: center;

    /* Black */

    color: #212121;
  }
  .circulo-preguntas-gde {
    width: 566px;
    height: 566px;
    border-radius: 50%;
    border: 2px solid #ffd60e;
    background: transparent;
    position: absolute;
    left: -180px;
    top: -200px;
    overflow: hidden;
    clip-path: inset(35% 0 0 0);
  }

  .circulo-preguntas {
    width: 468px;
    height: 468px;
    border-radius: 50%;
    border: 2px solid #ffd60e;
    background: transparent;
    position: absolute;
    left: -130px;
    top: -150px;
    overflow: hidden;
    clip-path: inset(32% 0 0 0);
  }

  .pregunta2 {
    max-width: 98% !important;
    height: auto !important;
  }

  .div-gmm {
    /* max-width: 100%; */
    width: 50% !important;
    height: auto !important;

    /* Black 5% */

    background: #f4f4f4;
    border-radius: 8px 8px 0px 0px;
  }

  .div-pregunta2-gmm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    gap: 10px;

    width: 100% !important;
    height: 35px;

    /* Azul 50% */

    background: #77cae3;
    /* Azul Inter */

    border-radius: 8px 8px 0px 0px;
  }

  .div-desc-michis-lomito {
    width: 574px !important;
    height: 60px !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-top: 3px;
    margin-left: 20px;

    color: #000000;
  }

  .div-img-aseguradora {
    width: 102px;
    height: 80px;
    /* border: 1px solid red; */
    margin-top: 3px;
  }

  .div-aseguradora {
    max-width: 775px;
    height: auto !important;
    margin-bottom: 10px;
    margin-left: 22px;
    /* border: 1px solid red; */
  }

  .py {
    padding-top: 40px !important;
    /* padding-right: 24px; */
  }

  .mx {
    margin-left: 97px !important;
    margin-right: 97px !important;
  }
}

/* Tablets ipad pro */

@media only screen and (min-width: 822px) and (max-width: 1010px) {
  .div-tablet-img2 {
    width: 413px;
    height: 300px;
    text-align: center;
    /* border: 1px solid red; */
  }

  .titulo-preguntas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    align-items: center;
    text-align: center;

    /* Black */

    color: #212121;
  }

  .div-tablet-titulo2 {
    min-width: 623px !important;
    max-width: 623px !important;
    height: 160px !important;
    text-align: center;
    /* border: 1px solid yellow; */
  }

  .pregunta2 {
    max-width: 98% !important;
    height: auto !important;
  }

  .div-gmm {
    /* max-width: 100%; */
    width: 50% !important;
    height: auto !important;

    /* Black 5% */

    background: #f4f4f4;
    border-radius: 8px 8px 0px 0px;
  }

  .div-pregunta2-gmm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    gap: 10px;

    width: 100% !important;
    height: 35px;

    /* Azul 50% */

    background: #77cae3;
    /* Azul Inter */
    border-radius: 8px 8px 0px 0px;
  }

  .div-desc-michis-lomito {
    width: 574px !important;
    height: 60px !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-top: 3px;
    margin-left: 20px;

    color: #000000;
  }

  .circulo-gde-preguntas {
    width: 362px;
    height: 362px;
    border-radius: 50%;
    border: 2px solid #ffd60e;
    background: transparent;
    position: absolute;
    right: -110px;
    top: -30px;
    overflow: hidden;
    clip-path: inset(9% 0 0 0);
  }

  .circulo-chico-preguntas {
    width: 262px;
    height: 262px;
    border-radius: 50%;
    border: 2px solid #ffd60e;
    background: transparent;
    position: absolute;
    right: -60px;
    top: 20px;
    overflow: hidden;
  }

  .div-img-aseguradora {
    width: 102px;
    height: 80px;
    /* border: 1px solid red; */
    margin-top: 12px;
  }

  .div-aseguradora {
    max-width: 437px;
    height: auto !important;
    margin-bottom: 10px;
    margin-left: 52px;
    /* border: 1px solid red; */
  }

  .py {
    padding-top: 60px;
    /* padding-right: 24px; */
  }
  .mx {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
}

/* Tablets lager */
@media only screen and (min-width: 801px) and (max-width: 821px) {
  .div-tablet-img2 {
    width: 413px;
    height: 300px;
    text-align: center;
    /* border: 1px solid red; */
  }

  .titulo-preguntas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    align-items: center;
    text-align: center;

    /* Black */

    color: #212121;
  }

  .div-tablet-titulo2 {
    min-width: 623px !important;
    max-width: 623px !important;
    height: 160px !important;
    text-align: center;
    /* border: 1px solid yellow; */
  }

  .pregunta2 {
    max-width: 98% !important;
    height: auto !important;
  }

  .div-gmm {
    /* max-width: 100%; */
    width: 50% !important;
    height: auto !important;

    /* Black 5% */

    background: #f4f4f4;
    border-radius: 8px 8px 0px 0px;
  }

  .div-pregunta2-gmm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    gap: 10px;

    width: 100% !important;
    height: 35px;

    /* Azul 50% */

    background: #77cae3;
    /* Azul Inter */

    border-radius: 8px 8px 0px 0px;
  }

  .div-desc-michis-lomito {
    width: 574px !important;
    height: 60px !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-top: 3px;
    margin-left: 20px;

    color: #000000;
  }

  .circulo-gde-preguntas {
    width: 362px;
    height: 362px;
    border-radius: 50%;
    border: 2px solid #ffd60e;
    background: transparent;
    position: absolute;
    right: -110px;
    top: -30px;
    overflow: hidden;
    clip-path: inset(9% 0 0 0);
  }

  .circulo-chico-preguntas {
    width: 262px;
    height: 262px;
    border-radius: 50%;
    border: 2px solid #ffd60e;
    background: transparent;
    position: absolute;
    right: -60px;
    top: 20px;
    overflow: hidden;
  }

  .div-img-aseguradora {
    width: 102px;
    height: 80px;
    /* border: 1px solid red; */
    margin-top: 12px;
  }

  .div-aseguradora {
    max-width: 437px;
    height: auto !important;
    margin-bottom: 10px;
    margin-left: 52px;
    /* border: 1px solid red; */
  }

  .py {
    padding-top: 60px;
    /* padding-right: 24px; */
  }
  .mx {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
}

/* Tablets y phablets */
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .div-tablet-img2 {
    width: 413px;
    min-height: 300px;
    /* max-height: 300px; */
    text-align: center;
    /* border: 1px solid red; */
  }

  .titulo-preguntas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    align-items: center;
    text-align: center;

    /* Black */

    color: #212121;
  }

  .div-tablet-titulo2 {
    min-width: 623px !important;
    max-width: 623px !important;
    height: 160px !important;
    text-align: center;
    /* border: 1px solid yellow; */
  }

  .pregunta2 {
    max-width: 98% !important;
    height: auto !important;
  }

  .div-gmm {
    /* max-width: 100%; */
    width: 55% !important;
    height: auto !important;

    /* Black 5% */

    background: #f4f4f4;
    border-radius: 8px 8px 0px 0px;
  }

  .div-pregunta2-gmm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    gap: 10px;

    width: 100% !important;
    height: 35px;

    /* Azul 50% */

    background: #77cae3;
    /* Azul Inter */

    border-radius: 8px 8px 0px 0px;
  }

  .div-desc-michis-lomito {
    width: 574px !important;
    height: 60px !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-top: 3px;
    margin-left: 12px;

    color: #000000;
  }

  .circulo-gde-preguntas {
    width: 362px;
    height: 362px;
    border-radius: 50%;
    border: 2px solid #ffd60e;
    background: transparent;
    position: absolute;
    right: -110px;
    top: -30px;
    overflow: hidden;
    clip-path: inset(9% 0 0 0);
  }

  .circulo-chico-preguntas {
    width: 262px;
    height: 262px;
    border-radius: 50%;
    border: 2px solid #ffd60e;
    background: transparent;
    position: absolute;
    right: -60px;
    top: 20px;
    overflow: hidden;
  }

  .div-img-aseguradora {
    width: 102px;
    height: 80px;
    /* border: 1px solid red; */
    margin-top: 12px;
  }

  .div-aseguradora {
    max-width: 437px;
    height: auto !important;
    margin-bottom: 10px;
    margin-left: 52px;
    /* border: 1px solid red; */
  }

  .back-button {
    color: #039ecc !important;
    size: 28px;
  }
  .py {
    padding-top: 60px;
    /* padding-right: 24px; */
  }
  .mx {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
}

/* Teléfonos LL */
@media only screen and (min-width: 426px) and (max-width: 767px) {
  .div-tablet-img2 {
    width: 322px;
    min-height: 160px;
    max-height: 260px;
    text-align: center;
    /* border: 1px solid red; */
  }

  .titulo-preguntas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: center;

    /* Black */

    color: #212121;
  }

  .div-tablet-titulo2 {
    min-width: 300px !important;
    max-width: 376px !important;
    height: auto;
    text-align: center;
    /* border: 1px solid yellow; */
  }

  .pregunta2 {
    max-width: 98% !important;
    height: auto !important;
  }

  .div-gmm {
    /* max-width: 100%; */
    width: 100% !important;
    height: auto !important;

    /* Black 5% */

    background: #f4f4f4 !important;
    border-radius: 8px 8px 0px 0px;
  }

  .div-pregunta2-gmm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    gap: 10px;

    width: 100% !important;
    height: 35px;

    /* Azul 50% */

    background: #77cae3;
    /* Azul Inter */

    border: 1px solid #039ecc;
    border-radius: 8px 8px 0px 0px;
  }

  .div-desc-michis-lomito {
    width: 273px !important;
    height: 120px !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-top: 3px;
    margin-left: 16px;

    color: #000000;
  }

  .div-img-aseguradora {
    width: 102px;
    height: 80px;
    /* border: 1px solid red; */
    margin-top: 12px;
  }

  .div-aseguradora {
    max-width: 437px;
    height: auto !important;
    margin-bottom: 32px;
    margin-left: 8px;
    /* border: 1px solid red; */
  }
  .py {
    padding-top: 60px;
  }

  .mx {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
}

/* Teléfonos L */
@media only screen and (min-width: 376px) and (max-width: 425px) {
  .div-tablet-img2 {
    width: 322px;
    min-height: 160px;
    max-height: 220px;
    text-align: center;
    /* border: 1px solid red; */
  }

  .titulo-preguntas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: center;

    /* Black */

    color: #212121;
  }

  .div-tablet-titulo2 {
    width: 300px !important;
    height: auto;
    text-align: center;
    /* border: 1px solid yellow; */
  }
  .pregunta2 {
    max-width: 98% !important;
    height: auto !important;
  }

  .div-gmm {
    /* max-width: 100%; */
    width: 100% !important;
    height: auto !important;

    /* Black 5% */

    background: #f4f4f4 !important;
    border-radius: 8px 8px 0px 0px;
  }

  .div-pregunta2-gmm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    gap: 10px;

    width: 100% !important;
    /* min-width: 296px !important;
    max-width: 316px !important; */
    height: 35px;

    /* Azul 50% */

    background: #77cae3;
    /* Azul Inter */
    border-radius: 8px 8px 0px 0px;
  }

  .div-desc-michis-lomito {
    width: 273px !important;
    height: 120px !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-top: 3px;
    margin-left: 16px;

    color: #000000;
  }

  .div-img-aseguradora {
    width: 102px;
    height: 80px;
    /* border: 1px solid red; */
    margin-top: 12px;
  }

  .div-aseguradora {
    max-width: 292px;
    height: auto !important;
    margin-bottom: 32px;
    margin-left: 8px;
    /* border: 1px solid red; */
  }

  .py {
    padding-top: 60px;
    /* padding-right: 24px; */
  }

  .mx {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
}

/* Teléfonos M */
@media only screen and (min-width: 321px) and (max-width: 375px) {
  .div-tablet-img2 {
    width: 300px;
    min-height: 160px;
    max-height: 200px;
    text-align: center;
    /* border: 1px solid red; */
  }

  .titulo-preguntas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: center;

    /* Black */

    color: #212121;
  }

  .div-tablet-titulo2 {
    width: 300px !important;
    height: auto;
    text-align: center;
    /* margin-top: 110px !important; */
    /* border: 1px solid yellow; */
  }

  .pregunta2 {
    max-width: 98% !important;
    height: auto !important;
  }

  .div-gmm {
    /* max-width: 100%; */
    width: 100% !important;
    height: auto !important;

    /* Black 5% */

    background: #f4f4f4 !important;
    border-radius: 8px 8px 0px 0px;
  }

  .div-pregunta2-gmm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    gap: 10px;

    width: 100% !important;
    height: 35px;

    /* Azul 50% */

    background: #77cae3;
    /* Azul Inter */

    border-radius: 8px 8px 0px 0px;
  }
  .div-desc-michis-lomito {
    width: 273px !important;
    height: 120px !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-top: 3px;
    margin-left: 12px;

    color: #000000;
  }

  .div-img-aseguradora {
    width: 102px;
    height: 80px;
    /* border: 1px solid red; */
    margin-top: 12px;
  }

  .div-aseguradora {
    max-width: 292px;
    height: auto !important;
    margin-bottom: 32px;
    margin-left: 8px;
    /* border: 1px solid red; */
  }

  .py {
    padding-top: 60px;
  }

  .mx {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
}

/* Teléfonos S */
@media only screen and (min-width: 0px) and (max-width: 320px) {
  .titulo-img {
    width: 100% !important;
    height: auto;
    /* border: 1px solid brown; */
  }
  .titulo-preguntas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: center;

    /* Black */

    color: #212121;
  }

  .div-tablet-titulo2 {
    width: 300px !important;
    height: auto;
    text-align: center;
    /* border: 1px solid yellow; */
  }

  .div-tablet-img2 {
    width: 300px !important;
    min-height: 120px;
    max-height: 180px;
    text-align: center;
    /* border: 1px solid red; */
  }

  .pregunta2 {
    max-width: 100%;
    min-height: 950px !important;
    max-height: 1000px !important;
  }

  .div-gmm {
    /* max-width: 100%; */
    width: 100% !important;
    height: auto !important;

    /* Black 5% */

    background: #f4f4f4 !important;
    border-radius: 8px 8px 0px 0px;
  }

  .div-pregunta2-gmm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    gap: 10px;

    width: 100% !important;
    height: 35px;

    /* Azul 50% */

    background: #77cae3;
    /* Azul Inter */

    border-radius: 8px 8px 0px 0px;
  }

  .div-desc-michis-lomito {
    width: 273px !important;
    height: 120px !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-top: 3px;
    margin-left: 12px;

    color: #000000;
  }

  .body-aseguradora {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;

    color: #000000;
  }

  .div-img-aseguradora {
    width: 102px;
    height: 80px;
    /* border: 1px solid red; */
    margin-top: 12px;
  }

  .div-aseguradora {
    max-width: 292px;
    height: auto !important;
    margin-bottom: 32px;
    margin-left: 8px;
    /* border: 1px solid red; */
  }

  .py {
    padding-top: 40px;
    /* padding-right: 24px; */
  }

  .mx {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
}

/* ++++++++++img ++++++++++++++++++ */
@media only screen and (max-width: 1010px) {
  .img-abajo {
    display: none;
  }
}

@media only screen and (min-width: 1011px) {
  .img-arriba {
    display: none;
  }
}

/* @media only screen and (min-width: 1024px) {
  .div-tabla {
    padding-left: 90px !important;
  }
} */
