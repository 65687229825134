.container-inicio {
  position: relative;
}

.textPF {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  padding-top: 5rem;

  color: #212121;
}

.div-title {
  width: 946px;
  height: 118px;
  align-content: center;
}

.text-title {
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 59px;
  text-align: center;
  letter-spacing: 0.5px;
}

.conten {
  height: 100%;
  width: 100%;
}

.seguro {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 49px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.5px;

  /* Black */

  color: #212121;
}

.coberturas {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.5px;

  /* Black */

  color: #212121;
}

.div-azul {
  display: flex;
  width: 100%;
  height: auto;

  /* azul 5% */

  background: #f2fafc;
}

.div-azul2-c {
  width: 100%;
  height: 610px;
  justify-content: center;
  align-items: center;

  /* azul 5% */

  background: #f2fafc;
}

.title-azul {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: center;

  /* Black */

  color: #212121;
}

.coberturas-titulo {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #0a3949;
}

.coberturas-decripcion {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: #212121;
}

.primero-primero-c {
  justify-content: center;
  align-items: center;
  padding: 10px 58px;

  width: 1000px;
  height: 430px;

  /* White */

  background: #ffffff;
  box-shadow: 4px 6px 16px rgba(33, 33, 33, 0.2);
  border-radius: 8px;

  /* Inside auto layout */

  flex-grow: 0;
}

.titulo-primero {
  width: 720px;
  height: 58px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  /* Black */

  color: #212121;
}

.gato-perro {
  box-sizing: border-box;
  width: 300px;
  height: 300px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  /* Azul 50% */

  border: 1px solid #77cae3;
  border-radius: 10px;
}

.text-perro-gato {
  width: 104px;
  height: 34px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
  text-align: center;
  text-transform: uppercase;

  /* Azul 50% */

  color: #77cae3;
}

.img-gato {
  width: 164px;
  height: 275px;
  margin: 10px auto;
  display: block;
}

.img-perro {
  width: 244.54px;
  height: 205.03px;
  margin: 10px auto;
  display: block;
}

.tittulo-beneficios {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: center;

  /* Black */

  color: #212121;
}
.text-puntos {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: #0a3949;
}

.pl-vineta {
  padding-left: 50px !important;
}

.container-wrapper {
  background-color: #e5e5e5;
  display: flex;
}

.pl-requisitos {
  padding-left: 85px !important;
}

.pl-requisitos1 {
  padding-right: 50px !important;
}

.text-requisitos {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  /* Black */

  color: #212121;
}

.titulo-requisitos {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;

  /* Black */

  color: #212121;
}

.imagen-landing {
  max-height: 500px;
  width: 300px;
}

.ver-planes {
  width: 382px;
  height: 48px;
  left: 0px;
  top: 0.29px;
}

.text-planes {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  /* White */

  color: #ffffff;
}

.flex-container {
  display: flex;
  align-items: center;
}

.custom-icon {
  margin: 0 0 4px 4px;
}
.text-num-cotiza {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  display: flex;
  align-items: center;
  text-align: right;
  text-decoration-line: underline;

  /* Brand/Blue */

  color: #039ecc;
}

.href-terminos {
  padding-left: 0.8rem;
  color: #039ecc;
}

.acoerdeon-content {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
}
.acordeon-titulo {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  /* Black 75% */

  color: #595959;
}

.pregunta2 {
  max-width: 100%;
  height: auto;

  /* Black 5% */

  background: #f4f4f4;
  margin-bottom: 6px !important;
}

.div-pregunta2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  gap: 10px;

  width: auto;
  height: 35px;

  /* Azul 50% */

  background: #77cae3;
  /* Azul Inter */

  border-radius: 8px 8px 0px 0px;
}

.text-div-pregunta2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #000000;
}

.text-content-pregunta2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
}

.div-gmm {
  max-width: 100%;
  height: auto;

  /* Black 5% */

  background: #f4f4f4;
}

.pregunta-tres {
  max-width: 80%;
  height: auto;
}

.div-respuesta2 {
  width: 100%;
  height: auto;
}

.seccion-preguntas {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  color: #0a3949;
}

.preguntas-link {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  color: #009dd0;
}
