.div-modal {
  width: 466px;
  height: auto;
}
.div-titulo-text-f {
  width: 356px;
  height: 68px;
  text-align: center;
}
.span-titulo-text-f {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: center;

  /* Black */

  color: #212121;
}
.div-image-f {
  width: 394px;
  height: 192px;
}

.div-desc-f {
  width: 369px;
  height: 144px;
}
.span-text-desc-f {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */

  letter-spacing: 0.5px;

  /* Black */

  color: #212121;
}
.div-text-news-f {
  width: 362px;
  height: 92px;
}
.span-text-news-f {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  color: #212121;
}

/*@media only screen and (max-width: 767px) {
  .div-modal {
    width: 394px;
    height: auto;
  }*/

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .div-modal {
    width: 390px !important;
    height: auto;
  }

  .div-titulo-text-f {
    width: 272px;
    height: 102px;
    text-align: center;
  }
  .span-titulo-text-f {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;

    /* Black */

    color: #212121;
  }

  .div-image-f {
    width: 276px;
    height: 134px;
  }

  .div-desc-f {
    width: 281px !important;
    height: 192px !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    /*letter-spacing: 0.5px;*/

    /* Black */

    color: #212121;
  }

  .div-desc-f {
    width: 281px;
    height: 192px;
  }

  .span-text-desc-f {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .div-text-news-f {
    width: 281px;
    height: 96px;
  }
  .span-text-news-f {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    color: #212121;
  }
}
