.headBand{
    position: relative;
    width: auto;
    height: auto;
    top:65px;
    left: -40px;
    display: flex;
    justify-content:center;
    align-items:center;
    text-align:center;
}
.resizeImg{
    position: absolute;
    top:-185px;
    left:302px;
    width: auto;
    height: 385px;
}
.resizeImgText{

}

/* pantalla grande  */
@media only screen and (min-width: 1440px) and (max-width: 10000px) {
    .headBand{
        position: relative;
        width: auto;
        height: auto;
        top:65px;
        left: -40px;
        display: flex;
        justify-content:center;
        align-items:center;
        text-align:center;
    }
    .resizeImg{
        position: absolute;
        top:-185px;
        left:302px;
        width: auto;
        height: 385px;
    }
    .resizeImgText{

    }
}

/* Escritorio grande */
@media only screen and (min-width: 1026px) and (max-width: 1440px) {
    .headBand{
        position: relative;
        width: auto;
        height: auto;
        top:65px;
        left: -40px;
        display: flex;
        justify-content:center;
        align-items:center;
        text-align:center;
    }
    .resizeImg{
        position: absolute;
        top:-185px;
        left:302px;
        width: auto;
        height: 385px;
    }
    .resizeImgText{

    }
}

/* Laptop */
@media only screen and (min-width: 1010px) and (max-width: 1025px) {
    .headBand{
        position: relative;
        width: auto;
        height: auto;
        top:65px;
        left: -40px;
        display: flex;
        justify-content:center;
        align-items:center;
        text-align:center;
    }
    .resizeImg{
        position: absolute;
        top:-185px;
        left:302px;
        width: auto;
        height: 385px;
    }
    .resizeImgText{

    }
}

/* Tablets ipad pro */
@media only screen and (min-width: 821px) and (max-width: 1010px) {
    .headBand{
        position: relative;
        width: auto;
        height: auto;
        top:30px;
        left: -35px;
        display: flex;
        justify-content:center;
        align-items:center;
        text-align:center;
    }
    .resizeImg{
        position: absolute;
        top:-175px;
        left:120px;
        width: 400px;
        height: auto;
    }
}

/* Tablets lager */
@media only screen and (min-width: 801px) and (max-width: 821px) {
    .headBand{
        position: relative;
        width: auto;
        height: auto;
        top:30px;
        left: -35px;
        display: flex;
        justify-content:center;
        align-items:center;
        text-align:center;
    }
    .resizeImg{
        position: absolute;
        top:-175px;
        left:120px;
        width: 400px;
        height: auto;
    }
}

/* Tablets y phablets */
@media only screen and (min-width: 768px) and (max-width: 800px) {
    .headBand{
        position: relative;
        width: auto;
        height: auto;
        top:30px;
        left: -35px;
        display: flex;
        justify-content:center;
        align-items:center;
        text-align:center;
    }
    .resizeImg{
        position: absolute;
        top:-175px;
        left:120px;
        width: 400px;
        height: auto;
    }

}

/* Teléfonos LL */
@media only screen and (min-width: 426px) and (max-width: 767px) {
    .headBand{
        margin-top: 20px;
        position: relative;
        width: auto;
        height: auto;
        top:0px;
        left: -30px;
        display: flex;
        justify-content:center;
        align-items:center;
        text-align:center;
    }
    .resizeImgText{
        width: auto;
        height: auto;
    }
    .resizeImg{
        position: absolute;
        top:-185px;
        left:95px;
        width: 400px;
        height: auto;
    }
}

/* Teléfonos L */
@media only screen and (min-width: 376px) and (max-width: 425px) {
    .headBand{
        position: relative;
        width: auto;
        height: auto;
        top:0px;
        left: -30px;
        display: flex;
        justify-content:center;
        align-items:center;
        text-align:center;
    }
    .resizeImgText{
        width: auto;
        height: auto;
    }
    .resizeImg{
        position: absolute;
        top:-185px;
        left:95px;
        width: 400px;
        height: auto;
    }
}

/* Teléfonos M */
@media only screen and (min-width: 321px) and (max-width: 375px) {
    .headBand{
        position: relative;
        width: auto;
        height: auto;
        top:0px;
        left: -15px;
        display: flex;
        justify-content:center;
        align-items:center;
        text-align:center;
    }
    .resizeImgText{
        width: 240px;
        height: auto;
    }
    .resizeImg{
        position: absolute;
        top:-160px;
        left:105px;
        width: 360px;
        height: auto;
    }
}

/* Teléfonos S */
@media only screen and (min-width: 0px) and (max-width: 320px) {
    .headBand{
        position: relative;
        width: auto;
        height: auto;
        top:0px;
        left: -0px;
        display: flex;
        justify-content:center;
        align-items:center;
        text-align:center;
    }
    .resizeImgText{
        width: 235px;
        height: auto;
    }
    .resizeImg{
        position: absolute;
        top:-160px;
        left:105px;
        width: 350px;
        height: auto;
    }
}
