.container-pet-photos {
  padding-top: 1%;
  position: relative;
  width: 100% !important;
  padding-bottom: 2%;
}

.hidde-app-add-pet {
}

.hideButtonMobile {
}
.hideButtonDescktop {
}

@media only screen and (min-width: 1441px) and (max-width: 2560px) {
  .content-photo {
    width: 500px;
    height: auto;
    margin-top: 22px;
    align-items: center;
    justify-content: center;
  }
  .hideButtonMobile {
    display: none;
  }
}
@media only screen and (min-width: 1023px) and (max-width: 1440px) {
  .content-photo {
    width: 500px;
    height: auto;
    margin-top: 22px;
    align-items: center;
    justify-content: center;
  }
  .hideButtonMobile {
    display: none;
  }
}


@media only screen and (min-width: 821px) and (max-width: 1023px) {
  .hidde-app-add-pet {
    display: none;
  }
  .content-photo {
    width: 458px;
    height: auto;
    margin-top: 22px;
    align-items: center;
    justify-content: center;
  }
  .hideButtonDescktop {
    display: none;
  }
}


@media only screen and (min-width: 768px) and (max-width: 820px) {
  .hidde-app-add-pet {
    display: none;
  }
  .content-photo {
    width: 458px;
    height: auto;
    margin-top: 22px;
    align-items: center;
    justify-content: center;
  }
  .hideButtonDescktop {
    display: none;
  }
}
@media only screen and (min-width: 0px) and (max-width: 766px) {
  .hidde-app-add-pet {
    display: none;
  }
  .content-photo {
    width: 301px !important;
    height: auto;
    margin-top: 22px;
    align-items: center;
    justify-content: center;
  }

  .hideButtonDescktop {
    display: none;
  }
}

.sube-foto {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  /* Black */

  color: #212121;
}

/* pantalla grande  */
@media only screen and (min-width: 1441px) and (max-width: 10000px) {
  .circle-photo {
    /* min-width: 429px;
    min-height: 429px; */
    min-width: 225px;
    min-height: 225px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;

    /* Black 5% */

    background: #f4f4f4;
  }
  .div-circle {
    /* padding-top: 25%; */
    padding-top: 10%;
  }
}

/* Escritorio grande */
@media only screen and (min-width: 1026px) and (max-width: 1440px) {
  .circle-photo {
    /* min-width: 429px;
    min-height: 429px; */
    min-width: 225px;
    min-height: 225px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;

    /* Black 5% */

    background: #f4f4f4;
  }
  .div-circle {
    /* padding-top: 25%; */
    padding-top: 10%;
  }
}

/* Laptop */
@media only screen and (min-width: 1011px) and (max-width: 1025px) {
  .circle-photo {
    /* min-width: 429px;
    min-height: 429px; */
    min-width: 225px;
    min-height: 225px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;

    /* Black 5% */

    background: #f4f4f4;
  }
  .div-circle {
    /* padding-top: 25%; */
    padding-top: 10%;
  }
}

/* Tablets ipad pro */
@media only screen and (min-width: 821px) and (max-width: 1010px) {
  .circle-photo {
    /* min-width: 429px;
    min-height: 429px; */
    min-width: 225px;
    min-height: 225px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;

    /* Black 5% */

    background: #f4f4f4;
  }

  .div-circle {
    /* padding-top: 25%; */
    padding-top: 10%;
  }
}

/* Tablets lager */
@media only screen and (min-width: 801px) and (max-width: 821px) {
  .circle-photo {
    /* min-width: 429px;
    min-height: 429px; */
    min-width: 225px;
    min-height: 225px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;

    /* Black 5% */

    background: #f4f4f4;
  }

  .div-circle {
    /* padding-top: 25%; */
    padding-top: 10%;
  }
}

/* Tablets y phablets */

@media only screen and (min-width: 768px) and (max-width: 800px) {
  .circle-photo {
    /* min-width: 429px;
    min-height: 429px; */
    min-width: 225px;
    min-height: 225px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;

    /* Black 5% */

    background: #f4f4f4;
  }

  .div-circle {
    /* padding-top: 25%; */
    padding-top: 10%;
  }
}

/* Teléfonos LL */
@media only screen and (min-width: 426px) and (max-width: 767px) {
  .circle-photo {
    min-width: 225px;
    min-height: 225px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;

    /* Black 5% */

    background: #f4f4f4;
  }

  .div-circle {
    padding-top: 10%;
  }
}

/* Teléfonos L */
@media only screen and (min-width: 376px) and (max-width: 425px) {
  .circle-photo {
    min-width: 225px;
    min-height: 225px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;

    /* Black 5% */

    background: #f4f4f4;
  }

  .div-circle {
    padding-top: 10%;
  }
}

/* Teléfonos M */
@media only screen and (min-width: 321px) and (max-width: 375px) {
  .circle-photo {
    min-width: 225px;
    min-height: 225px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;

    /* Black 5% */

    background: #f4f4f4;
  }

  .div-circle {
    padding-top: 10%;
  }
}
/* Teléfonos S fco*/
@media only screen and (min-width: 0px) and (max-width: 320px) {
  .circle-photo {
    min-width: 225px;
    min-height: 225px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;

    /* Black 5% */

    background: #f4f4f4;
  }

  .div-circle {
    padding-top: 10%;
  }
}

.text-photos {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #000000;
}

.text-modal-photos {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  /* or 140% */

  text-align: center;
  letter-spacing: 0.25px;

  /* Text/Body */

  color: #424242;
}

.text-pasos-modal-cel {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Text/Body */

  color: #424242;
}

.text-pasos-modal {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Text/Body */

  color: #424242;
}

.linea-amarilla {
  position: relative;
}

.titulo1-accordion {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin-left: 17px;
  /* identical to box height */

  /* Black */

  color: #212121;
}

.titulo2-accordion {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-left: 17px;

  /* Black */

  color: #212121;
}

.mascotas-photos {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background: #f4f4f4;
  border: 1px solid #f4f4f4;
}
