.conteiner-form-contratante {
  padding-top: 1%;
  position: relative;
  width: 100% !important;
  padding-bottom: 2%;
}

.title-contratante {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  /* Black */

  color: #212121;
}

.preguntas-contratante {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  /* Black */

  color: #212121;
}

@media only screen and (min-width: 1441px) and (max-width: 10000px) {
  .form-contratante {
    padding-right: 80px !important;
    padding-left: 80px !important;
    width: 558px;
    height: auto;
    /* border: 1px dotted #e5e5; */
  }
  .es-contratante {
    width: auto !important;
    height: 56px;
    flex-direction: row;
  }
  .boton-si {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 279px;
    height: 48px;

    background: #f5fbfd;
    border-radius: 8px;
  }

  .boton-no {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 279px;
    height: 48px;

    background: #f4f4f4;
    border-radius: 8px;
  }
}

/* Escritorio grande */
@media only screen and (min-width: 1026px) and (max-width: 1440px) {
  .form-contratante {
    width: 558px !important;
    height: auto;
    margin-left: 3px !important;
    padding-right: 81px !important;
    padding-left: 81px !important;
  }
  .es-contratante {
    width: 100% !important;
    height: 56px;
    flex-direction: row;
  }
  .boton-si {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 229px;
    height: 48px;

    background: #f5fbfd;
    border-radius: 8px;
  }

  .boton-no {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 229px;
    height: 48px;

    background: #f4f4f4;
    border-radius: 8px;
  }
}

/* Laptop */
@media only screen and (min-width: 1011px) and (max-width: 1025px) {
  .form-contratante {
    width: auto !important;
    padding-right: 40px !important;
    padding-left: 40px !important;
    height: auto;
  }
  .es-contratante {
    width: auto !important;
    height: auto !important;
    flex-direction: row !important;
  }
  .boton-si {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 279px;
    height: 48px;

    background: #f5fbfd;
    border-radius: 8px;
  }

  .boton-no {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 279px;
    height: 48px;

    background: #f4f4f4;
    border-radius: 8px;
  }
}

/* Tablets ipad pro */

@media only screen and (min-width: 821px) and (max-width: 1010px) {
  .form-contratante {
    width: auto !important;
    padding-right: 40px !important;
    padding-left: 40px !important;
    height: auto;
  }
  .es-contratante {
    width: auto !important;
    height: auto !important;
    flex-direction: row !important;
  }
  .boton-si {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 279px;
    height: 48px;

    background: #f5fbfd;
    border-radius: 8px;
  }

  .boton-no {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 279px;
    height: 48px;

    background: #f4f4f4;
    border-radius: 8px;
  }
}

/* Tablets lager */
@media only screen and (min-width: 801px) and (max-width: 821px) {
  .form-contratante {
    width: 558px;
    height: auto;
  }
  .es-contratante {
    width: 558px;
    height: 56px;
    flex-direction: row;
  }
  .boton-si {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 279px;
    height: 48px;

    background: #f5fbfd;
    border-radius: 8px;
  }

  .boton-no {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 279px;
    height: 48px;

    background: #f4f4f4;
    border-radius: 8px;
  }
}

/* Tablets y phablets */

@media only screen and (min-width: 768px) and (max-width: 800px) {
  .form-contratante {
    width: 558px;
    height: auto;
  }
  .es-contratante {
    width: 558px;
    height: 56px;
    flex-direction: row;
  }
  .boton-si {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 279px;
    height: 48px;

    background: #f5fbfd;
    border-radius: 8px;
  }

  .boton-no {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 279px;
    height: 48px;

    background: #f4f4f4;
    border-radius: 8px;
  }
}

/* Teléfonos LL */
@media only screen and (min-width: 426px) and (max-width: 767px) {
  .form-contratante {
    width: 301px !important;
    height: auto;
    margin-top: 22px;
    align-items: center;
    justify-content: center;
  }
  .es-contratante {
    width: 301px;
    height: 56px;
    flex-direction: row;
  }

  .boton-si {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 150px;
    height: 48px;

    background: #f5fbfd;
    border-radius: 8px;
  }

  .boton-no {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 150px;
    height: 48px;

    background: #f4f4f4;
    border-radius: 8px;
  }
}

/* Teléfonos L */
@media only screen and (min-width: 376px) and (max-width: 425px) {
  .form-contratante {
    width: 301px !important;
    height: auto;
    margin-top: 22px;
    align-items: center;
    justify-content: center;
  }
  .es-contratante {
    width: 301px;
    height: 56px;
    flex-direction: row;
  }

  .boton-si {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 150px;
    height: 48px;

    background: #f5fbfd;
    border-radius: 8px;
  }

  .boton-no {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 150px;
    height: 48px;

    background: #f4f4f4;
    border-radius: 8px;
  }
}

/* Teléfonos M */
@media only screen and (min-width: 321px) and (max-width: 375px) {
  .form-contratante {
    width: 301px !important;
    height: auto;
    margin-top: 22px;
    align-items: center;
    justify-content: center;
  }
  .es-contratante {
    width: 301px;
    height: 56px;
    flex-direction: row;
  }

  .boton-si {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 150px;
    height: 48px;

    background: #f5fbfd;
    border-radius: 8px;
  }

  .boton-no {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 150px;
    height: 48px;

    background: #f4f4f4;
    border-radius: 8px;
  }
}

/* Teléfonos S */
@media only screen and (min-width: 0px) and (max-width: 320px) {
  .form-contratante {
    width: 301px !important;
    height: auto;
    margin-top: 22px;
    align-items: center;
    justify-content: center;
  }
  .es-contratante {
    width: 301px;
    height: 56px;
    flex-direction: row;
  }

  .boton-si {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 150px;
    height: 48px;

    background: #f5fbfd;
    border-radius: 8px;
  }

  .boton-no {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 150px;
    height: 48px;

    background: #f4f4f4;
    border-radius: 8px;
  }
}

.w-100-step {
  width: auto !important;
}

.espacio {
  padding-top: 1rem;
}

.href-terminos {
  padding-left: 0.1rem;
  color: #039ecc;
}

.s-genero-tooltip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
