.title-procentaje {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 49px;
  /* identical to box height */
  text-align: center;
  /* Yellow */
  color: #ffd60e;
  -webkit-text-stroke: 3px black;
  -webkit-text-fill-color: #ffd60e;
}

.title-modal-discount-form {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  /* Black */
  color: #212121;
}

.title-modal-discount {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  /* Black */
  color: #212121;
}

.text-description-modal {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* Text/Body */
  color: #424242;
}

.modal-w {
  width: 435px !important;
  height: auto;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .modal-w {
    width: 100% !important;
    height: auto;
  }
}
