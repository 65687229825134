.card-item{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 0px;
    margin: 0px 10px 0px 10px;
    gap: 10px;
    width: 240px;
    height: auto!important;
    /* White */
    background: #FFFFFF;
    /* Azul 25% */
    border: 2px solid #C0E6F2;
    box-shadow: 0px 0px 16px rgba(33, 33, 33, 0.15);
    border-radius: 8px;
}

.card-item:hover {
    cursor: pointer !important;
    color: #039ecc !important;
    border: 2px solid #2cbeec;
}

.img-desk{
    position: relative;
    width: 90%;
    left: 5%;
    right: 5%;
    height: 350px;
    border-radius: 12px;
    background-size: cover;
}

.img-desk-detail{
    position: relative;
    width: 90%;
    left: 5%;
    right: 5%;
    height: 446px;
    border-radius: 12px;
    background-size: cover;
}


.detail-card {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center;
    padding: 24px 0px;
    gap: 0px;
    width: 1032px;
    height: auto!important;
    /* White */
    background: #FFFFFF;
    /* Azul 25% */
    border: 2px solid #C0E6F2;
    box-shadow: 0px 0px 16px rgba(33, 33, 33, 0.15);
    border-radius: 8px;
}

.detail-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* Black */
    color: #212121;
}

/* pantalla grande  */
@media only screen  and (min-width: 1440px) and (max-width: 10000px) {
  .card-item{
      width: 240px;
      height: 494px;
  }
    .detail-card{
        width: 1032px;
        height: auto!important;
    }
}

/* Escritorio grande */
@media only screen and (min-width: 1025px)  and (max-width: 1440px) {
    .card-item{
        width: 240px;
        height: 494px;
    }

    .detail-card{
        width: 1032px;
        height: auto!important;
    }

}

/* Laptop */
@media only screen and (min-width: 821px) and (max-width: 1024px) {
    .card-item{
        width: 200px!important;
        height: auto !important;
        margin: 0px 8px 0px 8px!important;
        padding: 24px 0px!important;
    }
    .img-desk{
        height: 300px;
    }

    .detail-card{
        width: 850px!important;
        height: auto!important;
    }
}
/* Tablets lager */
@media only screen and (min-width: 801px) and (max-width: 821px) {

}

/* Tablets y phablets */
@media only screen and (min-width: 768px) and (max-width: 800px) {

}

/* Teléfonos L */
@media only screen and (min-width: 376px) and (max-width: 767px) {

}

/* Teléfonos M */
@media only screen and (min-width: 321px) and (max-width: 375px) {

}

/* Teléfonos S */
@media only screen and (min-width: 0px) and (max-width: 320px) {

}