.content-foundations {
    height: auto!important;
    width:auto!important;
    background-color: #F4F4F4 !important;
}

.container-foundations {
    padding-top: 1%;
    position: relative;
}

.card-content-foundation {
    margin-left: -85px!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0px;
    margin-bottom: 35px;
    gap: 40px;
    width: 1140px!important;
    height: auto!important;
    /* White */
    background: #FFFFFF!important;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.card-blue-content-list {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 1072px;
    height: 638px;
    /* azul 5% */
    background: #F2FAFC;
    /* Azul 25% */
    border: 1px solid #C0E6F2;
    border-radius: 8px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.text-title-foundation {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    /* Black */
    color: #212121;
}

.text-title-desc {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 20px;
    line-height: 32px;
    /* Black */
    color: #212121;
}

.text-link {
    font-family: "Montserrat" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    /* identical to box height, or 171% */

    align-items: center !important;
    text-align: right;
    /*text-decoration-line: underline;*/
    text-transform: none !important;

    /* Brand/Blue */

    color: #039ecc;
}

.text-title-des2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    color: #000000;
}

.text-title-list {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    /* Black */
    color: #212121;
}

.desk-list-hidden {

}

.tablet-list-hidden {

}

.mobile-list-hidden {

}

.text-button-discard{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* identical to box height */
    text-align: center;
    text-transform: uppercase;
    /* Azul Inter */
    color: #101213;
}

/* pantalla grande  */
@media only screen  and (min-width: 1440px) and (max-width: 10000px) {
    .card-content-foundation {
        width: 1442px !important;
        margin-left: -231px!important;
        height: auto !important;
    }

    .card-blue-content-list {
        width: 1072px !important;
        height: auto !important;
        padding-bottom: 10px;
    }

    .tablet-list-hidden{
        display: none!important;
    }

    .mobile-list-hidden{
        display: none!important;
    }
}

/* Escritorio grande */
@media only screen and (min-width: 1025px)  and (max-width: 1440px) {
    .content-foundations {
        flex-direction: column;
        padding: 15px 50px 0px 0px;
        height: auto!important;
        width:auto!important;
        background-color: #F4F4F4 !important;
    }
    .card-content-foundation {
        width: 1390px !important;
        margin-left: -180px!important;
        height: auto !important;
    }

    .card-blue-content-list {
        width: 1072px !important;
        height: auto !important;
        padding-bottom: 10px;
    }

    .tablet-list-hidden{
        display: none!important;
    }

    .mobile-list-hidden{
        display: none!important;
    }
}

/* Laptop */
@media only screen and (min-width: 1000px) and (max-width: 1024px) {
    .card-content-foundation {
        margin-left: 0px!important;
        width: 970px !important;
        height: auto !important;
        background-color: #FFFFFF!important;
    }

    .card-blue-content-list {
        width: 900px !important;
        height: auto !important;
        padding-bottom: 10px;

    }
    .tablet-list-hidden{
        display: none!important;
    }

    .mobile-list-hidden{
        display: none!important;
    }

}


/* ipad pro */
@media only screen and (min-width: 821px) and (max-width: 999px) {
    .card-content-foundation {
        margin-left: 0px!important;
        width: 688px !important;
        padding-bottom:32px!important;
        height: auto !important;
        background-color: #FFFFFF!important;
    }

    .card-blue-content-list {
        width: 640px !important;
        height: auto !important;
        padding-bottom: 10px;

    }
    .desk-list-hidden{
        display: none!important;
    }
    .mobile-list-hidden{
        display: none!important;
    }

}

/* Tablets lager */
@media only screen and (min-width: 801px) and (max-width: 821px) {
    .card-content-foundation {
        margin-left: 0px!important;
        width: 688px !important;
        height: auto !important;
    }

    .card-blue-content-list {
        width: 640px !important;
        height: auto !important;
    }
    .desk-list-hidden{
        display: none!important;
    }
    .mobile-list-hidden{
        display: none!important;
    }
}

/* Tablets y phablets */
@media only screen and (min-width: 768px) and (max-width: 800px) {
    .card-content-foundation {
        margin-left: 0px!important;
        width: 688px !important;
        height: auto !important;
        padding: 32px 0px !important;

    }

    .card-blue-content-list {
        width: 640px !important;
        height: auto !important;
        padding: 16px !important;
        gap: 10px;
    }
    .desk-list-hidden{
        display: none!important;
    }

    .mobile-list-hidden{
        display: none!important;
    }
}

/* Teléfonos L */
@media only screen and (min-width: 376px) and (max-width: 767px) {

    .card-content-foundation {
        margin-left: 0px!important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 32px 10px 32px 10px;
        gap: 40px;
        width: 340px!important;
        height: auto !important;
        /* White */
        background: #FFFFFF;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .card-blue-content-list {
        /* Frame 8773 */
        box-sizing: border-box;
        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items:center;
        padding: 16px;
        justify-content: center;
        text-align: center;
        gap: 10px;
        width: 339px;
        height: auto!important;
        /* azul 5% */
        background: #F2FAFC!important;
        /* Azul 25% */
        border: 1px solid #C0E6F2 !important;
        border-radius: 8px;
        /* Inside auto layout */
        flex: none;
        order: 2;
        flex-grow: 0;
    }

    .desk-list-hidden{
        display: none!important;
    }

    .tablet-list-hidden{
        display: none!important;
    }
}

/* Teléfonos M */
@media only screen and (min-width: 321px) and (max-width: 375px) {

    .card-content-foundation {
        margin-left: 0px!important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 32px 5px 32px 4px;
        gap: 40px;
        width: 330px!important;
        height: auto !important;
        /* White */
        background: #FFFFFF;
        /* Inside auto layout */
    }

    .card-blue-content-list {
        /* Frame 8773 */
        box-sizing: border-box;
        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 16px;
        gap: 10px;
        width: 319px;
        height: auto!important;
        /* azul 5% */
        background: #F2FAFC!important;
        /* Azul 25% */
        border: 1px solid #C0E6F2 !important;
        border-radius: 8px;
        /* Inside auto layout */
        flex: none;
        order: 2;
        flex-grow: 0;
    }

    .desk-list-hidden{
        display: none!important;
    }

    .tablet-list-hidden{
        display: none!important;
    }

}

/* Teléfonos S */
@media only screen and (min-width: 0px) and (max-width: 320px) {

    .card-content-foundation {
        margin-left: 0px!important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 0px 32px 0px;
        width: 100%!important;
        height: auto !important;
        /* White */
        background: #FFFFFF;
        /* Inside auto layout */
    }

    .card-blue-content-list {
        /* Frame 8773 */
        box-sizing: border-box;
        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 16px;
        gap: 10px;
        width: 90%!important;
        height: auto!important;
        /* azul 5% */
        background: #F2FAFC!important;
        /* Azul 25% */
        border: 1px solid #C0E6F2 !important;
        border-radius: 8px;
        /* Inside auto layout */
        flex: none;
        order: 2;
        flex-grow: 0;
    }

    .desk-list-hidden{
        display: none!important;
    }

    .tablet-list-hidden{
        display: none!important;
    }


}