.content-pay {
    height: auto!important;
    width:auto!important;
    background-color: #F4F4F4 !important;
}

.container-payment {
    padding-top: 1%;
    position: relative;
}

.card-content-form-pay{
    width: 805px!important;
    height: auto;
    /* White */
    background: white;
    border-radius: 8px;
}

.grid-card-detail-insurance {
    width: 100%!important;
}

.card-detail-plan-pay {
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    width: 315px;
    height: auto!important;
    background: #fff;
    border-radius: 10px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.card-folio-pay {
    width: 300px!important;
}

.text-title-folio{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    display: flex;
    align-items: center;
    /* Black */
    color: #212121;
    /* Inside auto layout */
    flex: none;
    order: 0;
}


.text-title-coupon{
    /* Body Bold */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    /* Black */
    color: #212121;
}

.card-img-desk-tablet {
}

.card-img-mobile {
}


.title-step{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    /* identical to box height, or 114% */
    letter-spacing: 0.5px;
    /* Black */
    color: #212121;
}

.subtitle-step{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
}

/* pantalla grande  */
@media only screen  and (min-width: 1440px) and (max-width: 10000px) {
    .content-pay {
        flex-direction: column;
        padding: 15px 302px 10px 10px;
        height: auto!important;
        width:auto!important;
        background-color: #F4F4F4 !important;
    }
    .card-content-form-pay{
        width: 805px!important;
    }
    .card-detail-plan-pay {
        width: 315px;

    }
    .card-folio-pay{
        width: 360px!important;
    }
    .card-img-mobile {
        display: none!important;
    }
}

/* Escritorio grande */
@media only screen and (min-width: 1025px)  and (max-width: 1440px) {
    .content-pay {
        flex-direction: column;
        padding: 15px 250px 10px 0px;
        height: auto!important;
        width:auto!important;
        background-color: #F4F4F4 !important;
    }
    .card-content-form-pay{
        width: 805px!important;
    }
    .card-detail-plan-pay {
        width: 293px!important;
    }

    .card-folio-pay{
        width: 315px!important;
    }
    .card-img-mobile {
        display: none!important;
    }
}

/* Laptop */
@media only screen and (min-width: 821px) and (max-width: 1024px) {
    .content-pay {
        flex-direction: column;
        padding: 15px 302px 10px 10px;
        height: auto!important;
        width:auto!important;
        background-color: #F4F4F4 !important;
    }
    .grid-card-detail-insurance {
        justify-content: left !important;
    }

    .card-content-form-pay{
        width: 590px!important;
        height: auto;
        /* White */
        background: white;
        border-radius: 8px;
    }

    .card-detail-plan-pay {
        width: 250px !important;
        height: auto!important;
        background-color: #ffffff !important;
    }

    .card-img-mobile {
        display: none!important;
    }

}

/* Tablets lager */
@media only screen and (min-width: 801px) and (max-width: 821px) {
    .content-pay {
        padding-top: 15px;

    }
    .grid-card-detail-insurance {
        justify-content: left !important;
    }
    .card-content-form-pay{
        width: 688px!important;

    }
    .card-detail-plan-pay {
        width: 533px !important;
        background-color: #fff !important;
        align-items: flex-end !important;
    }
    .card-folio-pay{
        width: 580px !important;
    }
    .card-img-mobile {
        display: none!important;
    }
}

/* Tablets y phablets */
@media only screen and (min-width: 768px) and (max-width: 800px) {
    .content-pay {
        padding-top: 15px;

    }
    .grid-card-detail-insurance {
        justify-content: left !important;
        width: auto!important;
    }
    .card-content-form-pay{
        width: 688px!important;
    }
    .card-detail-plan-pay {
        width: 533px!important;
        background-color: #fff !important;
        justify-content: center !important;
    }
    .card-folio-pay{
        width: 580px !important; ;
    }
    .card-img-mobile {
        display: none!important;
    }
}

/* Teléfonos */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .content-pay {
        padding-top: 15px;
    }
    .grid-card-detail-insurance {
        justify-content: center !important;
    }
    .card-content-form-pay{
        width: auto!important;
    }
    .card-detail-plan-pay {
        width: auto!important;
        height: auto!important;
    }
    .card-folio-pay{
        width: auto !important;
    }
    .card-img-desk-tablet {
        display: none!important;
    }
}


.edit-button-pay-two{
    color: #039ecc !important;
    position: relative;
    padding: 3px;
}

.edit-button-pay-two:hover{
    cursor: pointer !important;
    color: #039ecc !important;
    background-color: rgba(197, 197, 197, 0.41) !important;
    border-radius: 100% !important;
    padding: 3px;
    position: relative;
}


.edit-button-pay {
    color: #c0c0c0 !important;
    position: relative;
    padding: 3px;
}

.edit-button-pay:hover {
    cursor: pointer !important;
    color: #039ecc !important;
    background-color: rgba(197, 197, 197, 0.41) !important;
    border-radius: 100% !important;
    padding: 3px;
    position: relative;
}


.title-owner{
    height: 24px;
    /* Body 2 */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    /* Black */
    color: #212121;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.text-owner{
    height: 24px;
    /* Body 2 Bold */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    /* Black */

    color: #212121;
}