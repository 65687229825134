.content-cotiza {
  width: 98%;
  height: auto;
}
.cotiza {
  width: 100%;
  height: auto;
}

.div-azul-cotiza {
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  /* azul 5% */

  background: #f2fafc;
}

.titulo-cotiza {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center !important;

  /* Black */

  color: #212121;
}

.preguntas-cotiza {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* Black */

  color: #212121;
}

.no-cotiza {
  font-family: "Montserrat";
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  /* identical to box height, or 171% */

  display: flex !important;
  align-items: center;
  text-transform: none !important;

  /* Azul Inter */

  color: #039ecc;
}

.cotizando {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  height: 56px;
  /* identical to box height, or 150% */

  text-align: center;
  text-transform: none !important;

  /* White */

  color: #ffffff;
}
