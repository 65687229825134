


.title-modal-payment-type{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    margin-bottom: 5%;
    /* Black */
    color: #000000;
}

.modal-w-payment-type{
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 10px;
    outline:none;
    position: absolute;
    width: 453px;
    height: auto!important;
    left: 493px;
    top: 250px;
    /* White */
    background: #FFFFFF;
    /* Shadow-Card */
    border-radius: 8px;
}


.content-payment-type{
    box-sizing: border-box;
    /* Auto layout */
    flex-direction: row;
    align-items: flex-start;
    padding: 5%;
    margin: 10px;
    gap: 10px;
    width: 405px!important;
    height: auto;
    background: #FFFFFF;
    /* Black 25% */
    border: 1px solid #C7C7C7;
    border-radius: 10px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.text-title-payment-type{
    width: auto;
    height: 24px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #000000;
}


.check-select-payment-type{
    position: absolute;
    width: 35px;
    height: 35px;
    align-items: center!important;
    text-align: center!important;
    justify-content: center;
    border-radius: 36px;
    left: 85%;
    padding: 1px;
    margin-top:-40px;
    /* Green */
    background: #5AB52F;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .modal-w-payment-type{
        width: 100%!important;
        height: auto;
    }
}


@media only screen  and (min-width: 1440px) and (max-width: 10000px) {
    .modal-w-payment-type{
        position: absolute!important;
        width: 500px!important;
        height: auto!important;
        left: 33%!important;
        top: 12%!important;
    }
    .content-payment-type{
        position: relative!important;
        width: 405px!important;

    }
    .check-select-payment-type{
        left: 94%!important;
        top: 46%!important;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1024px) {
    .modal-w-payment-type{
        position: absolute!important;
        width: auto!important;
        height: auto!important;
        left: 200px!important;
        top: 12%!important;
    }
    .content-payment-type{
        position: relative!important;
        width: 405px!important;

    }
    .check-select-payment-type{
        left: 94%!important;
        top: 46%!important;
    }
}

/* Tablets lager */
@media only screen and (min-width: 801px) and (max-width: 820px){
    .check-select-payment-type{
        left: 94%!important;
        top: 49%!important;
    }
}


/* Tablets y phablets */
@media only screen and (min-width: 768px) and (max-width: 800px) {

    .modal-w-payment-type{
        position: absolute!important;
        width: auto!important;
        height: auto!important;
        left: 120px!important;
        top: 12%!important;
    }
    .content-payment-type{
        position: relative!important;
        width: 405px!important;

    }
    .check-select-payment-type{
        left: 94%!important;
        top: 49%!important;
    }
}

/* Teléfonos  LL */
@media only screen and (min-width: 426px) and (max-width: 767px) {
    .modal-w-payment-type{
        position: absolute!important;
        width: auto!important;
        height: auto!important;
        left: 12%!important;
        top: 12%!important;
    }
    .content-payment-type{
        position: relative!important;
        width: auto!important;

    }
    .check-select-payment-type{
        left: 86%!important;
    }
}

/* Teléfonos  L */
@media only screen and (min-width: 376px) and (max-width: 425px) {
    .modal-w-payment-type{
        position: absolute!important;
        width: auto!important;
        height: auto!important;
        left: 5%!important;
        right: 5%!important;
        top: 12%!important;
    }
    .content-payment-type{
        left: -7%!important;
        position: relative!important;
        width: 320px!important;

    }
    .check-select-payment-type{
        left: 94%!important;
        top: 49%!important;
    }
    .text-title-payment-type{
        width: auto;
        height: 24px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px!important;
        text-align: center;
        color: #000000;
    }
}

/* Teléfonos  M */
@media only screen and (min-width: 321px) and (max-width: 375px) {
    .modal-w-payment-type{
        position: absolute!important;
        width: auto!important;
        height: auto!important;
        left: 5%!important;
        right: 5%!important;
        top: 12%!important;
    }
    .content-payment-type{
        left: -12%!important;
        position: relative!important;
        width: 300px!important;

    }
    .check-select-payment-type{
        left: 92%!important;
        top: 40%!important;
    }
    .text-title-payment-type{
        width: auto;
        height: 24px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 13px!important;
        text-align: center;
        color: #000000;
    }
}


/* Teléfonos  S */
@media only screen and (min-width: 0px) and (max-width: 320px) {
    .modal-w-payment-type{
        position: absolute!important;
        width: auto!important;
        height: auto!important;
        left: 1%!important;
        right: 1%!important;
        top: 12%!important;
    }
    .content-payment-type{
        left: -10%!important;
        position: relative!important;
        width: 250px!important;

    }
    .check-select-payment-type{
        left: 92%!important;
        top: 44%!important;
    }
    .title-modal-payment-type{
        margin-top: -20px!important;
        left: -10%!important;
        position: relative!important;

    }
    .text-title-payment-type{
        width: auto;
        height: 24px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 11px!important;
        text-align: center;
        color: #000000;
    }
}