.card-item-tablet{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 0px 0px;
    margin: 0px 10px 20px 10px;
    gap: 10px;
    width: 607px;
    height: auto!important;
    /* White */
    background: #FFFFFF;
    /* Azul 25% */
    border: 2px solid #C0E6F2;
    box-shadow: 0px 0px 16px rgba(33, 33, 33, 0.15);
    border-radius: 8px;
}


.img-tablet{
    position: relative;
    width: 218px;
    left: 5%;
    right: 5%;
    height: 124px;
    border-radius: 12px;
    background-size: cover;
}

.img-tablet-detail {
    position: relative;
    width: 218px;
    left: 5%;
    right: 5%;
    height: 420px;
    border-radius: 12px;
    background-size: cover;
}





.detail-card-tablet {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 0px;
    gap: 0px;
    width: 1032px;
    height: auto!important;
    /* White */
    background: #FFFFFF;
    /* Azul 25% */
    border: 2px solid #C0E6F2;
    box-shadow: 0px 0px 16px rgba(33, 33, 33, 0.15);
    border-radius: 8px;
}

.detail-title-tablet {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* Black */
    color: #212121;
}

/* Tablets lager */
@media only screen and (min-width: 801px) and (max-width: 821px) {

}

/* Tablets y phablets */
@media only screen and (min-width: 768px) and (max-width: 800px) {

}

/* Teléfonos L */
@media only screen and (min-width: 376px) and (max-width: 767px) {

}

/* Teléfonos M */
@media only screen and (min-width: 321px) and (max-width: 375px) {

}

/* Teléfonos S */
@media only screen and (min-width: 0px) and (max-width: 320px) {

}