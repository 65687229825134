.w-owner {
  width: 100% !important;
}

.boton-si-owner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 10px;

  width: 50%;
  height: 48px;

  background: #f5fbfd;
  border-radius: 8px;
}

.boton-no-owner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 10px;

  width: 50%;
  height: 48px;

  background: #f4f4f4;
  border-radius: 8px;
}

.modal-preguntas-contratante {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  /* Black */

  color: #212121;
}
