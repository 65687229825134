.mX-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.linea-amarilla {
  position: relative;
}

.linea-amarilla img {
  left: 0;
  top: 0;
  position: absolute;
}

.text-queremos {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  margin-left: 17px;
  /* identical to box height, or 114% */

  letter-spacing: 0.5px;

  /* Black */

  color: #212121;
}

.text-asi {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 17px;
  /* identical to box height, or 150% */

  /* Black */

  color: #212121;
}

.numero {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 71px;
  /* identical to box height */

  /* Black */

  color: #212121;
}

/*verde*/
.text-disabled {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  margin-left: 17px;
  text-transform: none;
  /* identical to box height, or 114% */

  letter-spacing: 0.5px;

  /* Black 25% */

  color: #c7c7c7;
}

.text-asi-disabled {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 17px;
  text-transform: none;
  /* identical to box height, or 150% */

  /* Black 25% */

  color: #c7c7c7;
}

.num-disabled {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 71px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.15);
}


.w-100 {
  width: 100%;
}


