.container-inicio {
  position: relative;
}

.textPF {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  padding-top: 5rem;

  color: #212121;
}

.div-title {
  width: 946px;
  height: 118px;
  align-content: center;
}

.card-input-home {
  width: 674px;
  height: auto;
  /* Auto layout */
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 58px;
  gap: 10px;
  /* White */
  background: #ffffff;
  box-shadow: 4px 6px 16px rgba(33, 33, 33, 0.2);
  border-radius: 8px;
}

.card-content-plans-home {
  padding: 24px 58px;
  width: 1140px;
  height: auto;
  justify-content: center;
  /* White */
  background: #ffffff;
  box-shadow: 4px 6px 6px rgba(33, 33, 33, 0.2);
  border-radius: 8px;
}
.card-plan {
  box-sizing: border-box;
  /* Auto layout */
  display: block;
  flex-direction: row;
  padding: 0px 0px 24px;
  gap: 10px;
  width: 315px;
  height: auto;
  background: #ffffff;
  /* Black 25% */
  border: 1px solid #c7c7c7;
  border-radius: 10px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.card-plan-toolbar {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  width: 100%;
  height: 50px;
  /* Black */
  background: #212121;
  /* Black 25% */
  border: 0px solid #c7c7c7;
  border-radius: 10px 10px 0px 0px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.margin-content {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.icon-cat {
  width: 300px;
  justify-content: center;
  align-items: center;
  height: 301px;
  text-align: center;
  background-image: url("../../assets/landing/icon_cat_white.svg");
}

.icon-cat:hover {
  width: 300px;
  justify-content: center;
  align-items: center;
  height: 301px;
  text-align: center;
  background-image: url("../../assets/landing/icon_cat_hover.svg");
  cursor: pointer;
}

.icon-dog {
  width: 300px;
  justify-content: center;
  align-items: center;
  height: 301px;
  text-align: center;
  background-image: url("../../assets/landing/icon_dog_white.svg");
}

.icon-dog:hover {
  width: 300px;
  justify-content: center;
  align-items: center;
  height: 301px;
  text-align: center;
  background-image: url("../../assets/landing/icon_dog_hover.svg");
  cursor: pointer;
}

.content-component {
  width: 100%;
  justify-content: center;
  height: auto;
  align-items: center;
  padding: 5px 0px;
}

.primero-primero {
  width: 100%;
  justify-content: center;
  height: auto;
  align-items: center;
  padding: 5px 0px;
  /* White */

  background: #ffffff;
  box-shadow: 4px 6px 16px rgba(33, 33, 33, 0.2);
  border-radius: 8px;

  /* Inside auto layout */

  flex-grow: 0;
}

.titulo-primero {
  height: 58px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  /* Black */

  color: #212121;
}

.widthMobile{
  width: 100%;
  padding-right:60px;
}

/* pantalla grande  */
@media only screen and (min-width: 1440px) and (max-width: 10000px) {
  .primero-primero {
    width: 55% !important;
  }
  .content-component {
    width: auto !important;
  }
  .margin-content {
    margin-left: 20px;
    margin-right: 20px !important;
  }
  .icon-arrow {
    font-size: 50px !important;
  }
  .text-title-home {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    letter-spacing: 0.5px;
  }

  .circulo-gde-home {
    width: 506px;
    height: 506px;
    border-radius: 50%;
    border: 2px solid #ffd60e;
    background: transparent;
    position: absolute;
    right: -250px;
    top: 140px;
    overflow: hidden;
  }

  .circulo-home {
    width: 368px;
    height: 368px;
    border-radius: 50%;
    border: 2px solid #ffd60e;
    background: transparent;
    position: absolute;
    right: -185px;
    top: 210px;
    overflow: hidden;
  }
  .movil {
    display: none;
  }
}

/* Escritorio grande */
@media only screen and (min-width: 1026px) and (max-width: 1440px) {
  .primero-primero {
    width: 100% !important;
  }
  .content-component {
    width: auto !important;
  }

  .card-plan-toolbar {
    padding: 12px 10px;
  }

  .margin-content {
    margin-left: 20px;
    margin-right: 20px !important;
  }
  .icon-arrow {
    font-size: 30px !important;
  }
  .text-title-home {
    font-size: 48px !important;
    text-align: center;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    letter-spacing: 0.5px;
  }
  .card-content-plans-home {
    width: auto !important;
    height: auto !important;
    padding: 14px 58px !important;
  }
  .card-plan {
    width: auto !important;
    height: auto !important;
  }

  .movil {
    display: none;
  }
}
/* Laptop */
@media only screen and (min-width: 1010px) and (max-width: 1025px) {
  .content-component {
    width: auto !important;
  }
  .margin-content {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .icon-arrow {
    font-size: 40px !important;
  }
  .text-title-home {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    letter-spacing: 0.5px;
  }
  .card-content-plans-home {
    width: auto !important;
    height: auto !important;
    padding: 14px 5px 34px 5px !important;
  }
  .card-plan {
    width: 288px !important;
    height: auto !important;
  }

  .movil {
    display: none;
  }
}

/* Tablets ipad pro */

@media only screen and (min-width: 821px) and (max-width: 1010px) {
  .content-component {
    width: auto !important;
  }
  .margin-content {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .content-component {
    width: 100% !important;
  }

  .icon-arrow {
    font-size: 40px !important;
  }
  .text-title-home {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    letter-spacing: 0.5px;
  }
  .card-content-plans-home {
    width: auto !important;
    height: auto !important;
    padding: 14px 5px 34px 5px !important;
  }
  .card-plan {
    width: 507px !important;
    height: auto !important;
  }

  .movil {
    display: none;
  }

  .card-input-home {
    /* Auto layout */
    justify-content: center;
    align-items: center;
    padding: 24px 38px 44px 37px !important;
    width: 90% !important;
    height: auto !important;
    /* White */
    background: #ffffff;
    box-shadow: 4px 6px 16px rgba(33, 33, 33, 0.2);
    border-radius: 8px;
  }
}

/* Tablets lager */
@media only screen and (min-width: 801px) and (max-width: 821px) {
  .margin-content {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .content-component {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .icon-arrow {
    font-size: 40px !important;
  }
  .text-title-home {
    font-size: 48px;
    text-align: center;
  }
  .card-input-home {
    /* Auto layout */
    justify-content: center;
    align-items: center;
    padding: 24px 38px 44px 38px !important;
    width: 90% !important;
    height: auto !important;
    /* White */
    background: #ffffff;
    box-shadow: 4px 6px 16px rgba(33, 33, 33, 0.2);
    border-radius: 8px;
  }

  .card-content-plans-home {
    width: auto !important;
    height: auto !important;
    padding: 14px 5px 34px 5px !important;
  }
  .card-plan {
    width: 507px !important;
    height: auto !important;
  }

  .movil {
    display: none;
  }
}

/* Tablets y phablets */

@media only screen and (min-width: 768px) and (max-width: 800px) {
  .margin-content {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .content-component {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .text-title-home {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }
  .icon-arrow {
    font-size: 40px !important;
  }

  .card-input-home {
    /* Auto layout */
    justify-content: center;
    align-items: center;
    padding: 24px 38px 44px 38px !important;
    width: 89% !important;
    height: auto !important;
    /* White */
    background: #ffffff;
    box-shadow: 4px 6px 16px rgba(33, 33, 33, 0.2);
    border-radius: 8px;
  }

  .card-content-plans-home {
    width: auto !important;
    height: auto !important;
    padding: 14px 5px 34px 5px !important;
  }
  .card-plan {
    width: 507px !important;
    height: auto !important;
  }

  .movil {
    display: none;
  }
}

/* Teléfonos LL */
@media only screen and (min-width: 426px) and (max-width: 767px) {
  .widthMobile{
    width: 100% !important;
    padding-right:0px !important;
  }
  .arrow-content {
    margin-left: 3%;
    justify-self: center;
    align-self: center;
    width: 95% !important;
  }
  .titulo-primero {
    width: 200px !important;
    height: 58px !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    /* Black */

    color: #212121;
  }
  .icon-arrow {
    font-size: 28px !important;
  }
  .margin-content {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .content-component {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .text-title-home {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    text-align: center;

    /* Black */

    color: #212121;
  }

  .card-input-home {
    /* Auto layout */
    width: auto !important;
    height: auto !important;
    /* Auto layout */
    display: inline-block !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 24px 74.5px !important;
    background-color: white !important;
    gap: 0px !important;
    /* White */
    box-shadow: 4px 6px 16px rgba(33, 33, 33, 0.2);
    border-radius: 8px;
  }

  .card-content-plans-home {
    width: auto !important;
    height: auto !important;
    padding: 14px 5px 34px 5px !important;
  }
  .card-plan {
    width: 100% !important;
    height: auto !important;
  }

  .tablet-desk {
    display: none;
  }

  .seguro {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .coberturas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }
}

/* Teléfonos L */
@media only screen and (min-width: 376px) and (max-width: 425px) {
  .widthMobile{
    width: 100% !important;
    padding-right:0px !important;
  }
  .arrow-content {
    margin-left: 3%;
    justify-self: center;
    align-self: center;
    width: 95% !important;
  }
  .primero-primero {
    width: auto !important;
    justify-content: center;
    align-items: center;
  }
  .titulo-primero {
    width: 50% !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    align-self: center;
    text-align: center !important;

    /* Black */

    color: #212121;
  }
  .icon-arrow {
    font-size: 28px !important;
  }
  .margin-content {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .content-component {
    width: 100% !important;
    justify-content: center !important;
  }

  .text-title-home {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    text-align: center;

    /* Black */

    color: #212121;
  }

  .card-input-home {
    /* Auto layout */
    width: auto !important;
    height: auto !important;
    /* Auto layout */
    display: inline-block !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 24px 57px !important;
    background-color: white !important;
    gap: 0px !important;
    /* White */
    box-shadow: 4px 6px 16px rgba(33, 33, 33, 0.2);
    border-radius: 8px;
  }

  .card-content-plans-home {
    width: auto !important;
    height: auto !important;
    padding: 14px 5px 34px 5px !important;
  }
  .card-plan {
    width: 100% !important;
    height: auto !important;
  }

  .tablet-desk {
    display: none;
  }

  .seguro {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .coberturas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }


  .icon-dog {
    width: 200px;
    justify-content: center;
    align-items: center;
    height: 201px;
    text-align: center;
    background-image: url("../../assets/landing/icon_dog_white_mobile.svg");
  }

  .icon-dog:hover {
    width: 200px;
    justify-content: center;
    align-items: center;
    height: 201px;
    text-align: center;
    background-image: url("../../assets/landing/icon_dog_hover_mobile.svg");
    cursor: pointer;
  }

  .icon-cat {
    width: 200px;
    justify-content: center;
    align-items: center;
    height: 201px;
    text-align: center;
    background-image: url("../../assets/landing/icon_cat_white_mobile.svg");
  }

  .icon-cat:hover {
    width: 200px;
    justify-content: center;
    align-items: center;
    height: 201px;
    text-align: center;
    background-image: url("../../assets/landing/icon_cat_hover_mobile.svg");
    cursor: pointer;
  }
}

/* Teléfonos M */
@media only screen and (min-width: 321px) and (max-width: 375px) {
  .widthMobile{
    width: 100% !important;
    padding-right:0px !important;
  }
  .arrow-content {
    margin-left: 2%;
    justify-self: center;
    align-self: center;
    width: 95% !important;
  }

  .primero-primero {
    width: auto !important;
    justify-content: center;
    align-items: center;
  }

  .titulo-primero {
    width: 150px;
    height: 58px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    /* Black */

    color: #212121;
  }
  .icon-arrow {
    font-size: 28px !important;
  }
  .text-title-home {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    text-align: center;

    /* Black */

    color: #212121;
  }

  .card-input-home {
    /* Auto layout */
    width: auto !important;
    height: auto !important;
    /* Auto layout */
    display: inline-block !important;
    padding: 24px 41px !important;
    background-color: white !important;
    gap: 0px !important;
    /* White */
    box-shadow: 4px 6px 16px rgba(33, 33, 33, 0.2);
    border-radius: 8px;
  }

  .margin-content {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .content-component {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .card-content-plans-home {
    width: auto !important;
    height: auto !important;
    padding: 14px 5px 34px 5px !important;
  }
  .card-plan {
    width: 100% !important;
    height: auto !important;
  }

  .tablet-desk {
    display: none;
  }

  .seguro {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .coberturas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }


  .icon-dog {
    width: 200px;
    justify-content: center;
    align-items: center;
    height: 201px;
    text-align: center;
    background-image: url("../../assets/landing/icon_dog_white_mobile.svg");
  }

  .icon-dog:hover {
    width: 200px;
    justify-content: center;
    align-items: center;
    height: 201px;
    text-align: center;
    background-image: url("../../assets/landing/icon_dog_hover_mobile.svg");
    cursor: pointer;
  }

  .icon-cat {
    width: 200px;
    justify-content: center;
    align-items: center;
    height: 201px;
    text-align: center;
    background-image: url("../../assets/landing/icon_cat_white_mobile.svg");
  }

  .icon-cat:hover {
    width: 200px;
    justify-content: center;
    align-items: center;
    height: 201px;
    text-align: center;
    background-image: url("../../assets/landing/icon_cat_hover_mobile.svg");
    cursor: pointer;
  }
}

/* Teléfonos S */
@media only screen and (min-width: 0px) and (max-width: 320px) {
  .widthMobile{
    width: 100%!important;
    padding-right:0px!important;
  }
  .titulo-primero {
    width: 150px;
    height: 58px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    /* Black */

    color: #212121;
  }

  .primero-primero {
    width: 100% !important;
    justify-content: center;
    align-items: center;
    background-color: white !important;
  }

  .icon-arrow {
    font-size: 28px !important;
  }
  .margin-content {
    margin-left: -10px !important;
    margin-right: 0px !important;
  }
  .text-title-home {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    text-align: center;

    /* Black */

    color: #212121;
  }

  .card-input-home {
    /* Auto layout */
    justify-content: center;
    align-items: center;
    padding: 24px 28px 44px 28px !important;
    width: 80% !important;
    height: auto !important;
    /* White */
    background-color: white !important;
    box-shadow: 4px 6px 16px rgba(33, 33, 33, 0.2);
    border-radius: 8px;
  }

  .margin-content {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .content-component {
    width: auto !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .card-content-plans-home {
    width: auto !important;
    height: auto !important;
    padding: 14px 5px 34px 5px !important;
  }

  .card-plan {
    width: 100% !important;
    height: auto !important;
  }

  .tablet-desk {
    display: none;
  }

  .seguro {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .coberturas {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;

    /* Black */

    color: #212121;
  }

  .icon-cat {
    width: 250px !important;
    height: 251px !important;
    background-image: url("../../assets/landing/icon_cat_white.svg");
    background-size: cover;
  }
  .icon-cat:hover {
    width: 250px !important;
    height: 251px !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url("../../assets/landing/icon_cat_hover.svg");
    cursor: pointer;
    background-size: cover;
  }

  .icon-dog {
    width: 200px;
    justify-content: center;
    align-items: center;
    height: 201px;
    text-align: center;
    background-image: url("../../assets/landing/icon_dog_white_mobile.svg");
  }

  .icon-dog:hover {
    width: 200px;
    justify-content: center;
    align-items: center;
    height: 201px;
    text-align: center;
    background-image: url("../../assets/landing/icon_dog_hover_mobile.svg");
    cursor: pointer;
  }

  .icon-cat {
    width: 200px;
    justify-content: center;
    align-items: center;
    height: 201px;
    text-align: center;
    background-image: url("../../assets/landing/icon_cat_white_mobile.svg");
  }

  .icon-cat:hover {
    width: 200px;
    justify-content: center;
    align-items: center;
    height: 201px;
    text-align: center;
    background-image: url("../../assets/landing/icon_cat_hover_mobile.svg");
    cursor: pointer;
  }
}

.conten {
  height: 100%;
  width: 100%;
}

/*.seguro {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 49px;

  text-align: center;
  letter-spacing: 0.5px;


  color: #212121;
}

/*.coberturas {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #212121;
}*/

.div-azul {
  display: block;
  width: 100%;
  text-align: center;
  height: auto;

  /* azul 5% */

  background: #f2fafc;
}

.div-azul2 {
  display: block;
  width: 100%;
  text-align: center;
  justify-content: center;
  height: auto;

  /* azul 5% */

  background: #f2fafc;
}

.title-azul {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: center;

  /* Black */

  color: #212121;
}

.coberturas-titulo {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #0a3949;
}

.coberturas-decripcion {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: #212121;
}

.text-home-description-plan {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #0a3949;
}

.text-home-description-plan2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #039ecc;
}

.icon-arrow {
  cursor: pointer;
  color: #77cae3;
  font-size: 40px;
}

.icon-arrow:hover {
  cursor: pointer;
  color: white;
  background-color: #77cae3;
}

.text-perro-gato {
  width: 104px;
  height: 34px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
  text-align: center;
  text-transform: uppercase;

  /* Azul 50% */

  color: #77cae3;
}

.text-perro-gato:hover {
  /* Azul 50% */
  color: #ffffff;
}

.img-gato {
  width: 164px;
  height: 275px;
  margin: 10px auto;
  display: block;
}

.img-perro {
  width: 244.54px;
  height: 205.03px;
  margin: 10px auto;
  display: block;
}

.tittulo-beneficios {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: center;

  /* Black */

  color: #212121;
}
.text-puntos {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: #0a3949;
}

.pl-vineta {
  padding-left: 50px !important;
}

.pr-vineta {
  padding-right: 50px;
}

.container-wrapper {
  background-color: #e5e5e5;
  display: flex;
}

.pl-requisitos {
  padding-left: 85px !important;
}

.pl-requisitos1 {
  padding-right: 50px !important;
}

.text-requisitos {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  /* Black */

  color: #212121;
}

.titulo-requisitos {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  width: auto;
  height: auto;

  /* Black */

  color: #212121;
}

.ver-planes {
  width: 80%;
}

.div-ver-planes {
  width: 382px;
  height: 48px;
}

.text-planes {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  /* White */

  color: #ffffff;
}

.flex-container {
  display: flex;
  align-items: center;
}

.custom-icon {
  margin: 0 0 4px 4px;
}
.text-num-cotiza {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px;
  /* identical to box height, or 171% */

  display: flex !important;
  align-items: center !important;
  text-align: right;
  text-decoration-line: underline;
  text-transform: none !important;

  /* Brand/Blue */

  color: #039ecc;
}

.href-terminos {
  padding-left: 0.8rem;
  color: #039ecc;
}

.acoerdeon-content {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
}
.acordeon-titulo {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  /* Black 75% */

  color: #595959;
}
.pregunta2 {
  display: flex;
  padding: 0px;
  gap: 24px;

  width: 370px;
  height: 700px;

  /* Black 5% */

  background: #f4f4f4;
}

.div-pregunta2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  gap: 10px;

  width: auto;
  height: 35px;

  /* Azul 50% */

  background: #77cae3;
  /* Azul Inter */

  border-radius: 8px 8px 0px 0px;
}

.text-div-pregunta2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #000000;
}

.text-content-pregunta2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
}

.div-gmm {
  display: flex;
  padding: 0px;
  gap: 24px;

  width: 370px;
  height: auto;

  /* Black 5% */

  background: #f4f4f4;
}

.pregunta-tres {
  width: 487px;
  height: auto;
}

.div-requisitos {
  width: 100%;
  height: auto;
}

.div-requisitos2 {
  max-width: 90%;
}

.cubre {
  width: 80%;
  height: auto;
}

.boton {
  cursor: not-allowed;
  pointer-events: none;
}

.column {
  float: left;
  width: 33.33%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.text-title-plan {
  /* Plan Mascota */
  width: auto;
  height: 26px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  /* identical to box height */

  text-align: center;

  /* White */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.text-title-plan2 {
  /* Plan Mascota */
  width: auto;
  height: 26px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 21px;
  text-align: center;
  color: #ffffff;
}

.text-title-cost-plan {
  /* Cantidad */
  display: block;
  width: 100%;
  height: 34px;
  /* Heading 5 */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* Azul Inter */
  color: #039ecc;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.text-title-plan-annual {
  /* *Costo anual del seg */
  width: 100%;
  height: 17px;
  /* Body 5 */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #0a3949;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.text-title-plan-gastos-med {
  /* Gastos Medicos */
  width: 100%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  text-align: right;
  font-size: 14px;
  text-align: left !important;
  line-height: 17px;
  color: #0a3949;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.text-title-plan-description {
  width: 100% !important;
  height: 15px;

  /* Body 6 */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  /* Black */

  color: #212121;

  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}
.img-asegurable {
  display: block;
  background-color: #039ecc;
}
