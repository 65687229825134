.content-insurance {
  height: auto !important;
  width: auto !important;
  background-color: #f4f4f4 !important;
}

.container-insurance {
  padding-top: 1%;
  position: relative;
}

.card-complete {
  width: 797px;
  height: auto;
  margin-bottom: 5%;
  left: 150px;
  /* White */
  background: white;
  border-radius: 8px;
  padding-bottom: 3%;
}

.card-detail-plan {
  flex-direction: column;
  padding: 27px 12px 0px 12px;
  gap: 10px;
  width: 315px;
  height: 276px;
  background: #fff;
  border-radius: 10px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.card-blue {
  width: 328px;
  height: auto;
  left: 0px;
  top: 0px;
  background-color: #f2fafc;
  border-radius: 6px;
}

.card-gray {
  width: 328px;
  height: auto;
  left: 0px;
  top: 0px;
  background-color: RGB(89, 89, 89, 0.06);
  border-radius: 6px;
}

.card-tow-column {
  align-items: center;
  text-align: center;
  justify-content: center !important;
  height: auto !important;
}
.card-tow-column-blue {
  width: 100%;
  border-radius: 2px;
  align-items: center;
  text-align: center;
  background-color: #c0e6f2;
  justify-content: center !important;
  height: 100%;
}

.text-bene-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */
  /* Black */
  color: #212121;
}
.text-bene-cost {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  /* Black */
  color: #212121;
}

.text-cobeturaanual-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */
  text-align: left !important;
  /* Black */
  color: #212121;
}
.text-cobeturaanual-const {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  text-align: right !important;
  /* Black */
  color: #212121;
}

.text-cobeturaanual-description {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  /* or 140% */
  /* Black */
  color: #212121;
}

.content-attendance {
  width: 100% !important;
  height: 45px;
  margin-top: 4% !important;
  margin-bottom: 2% !important;
}

.card-content-attendance {
  padding-top: 10px;
  justify-content: center !important;
  height: 35px !important;
  width: 509px !important;
  background-image: url("../../assets/landing/arrrow-destok-yellow.svg") !important;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  margin-left: -4% !important;
}

.card-content-attendance2 {
  padding-top: 10px;
  align-items: center !important;
  justify-content: center !important;
  height: 19px !important;
  width: 380px !important;
  background-image: url("../../assets/landing/arrow-movil-yellow.svg") !important;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
}

.text-attendance-title {
  /* Heading 7 */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  color: #212121;
  margin-left: 13% !important;
}

.text-attendance-title2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  margin-left: 13% !important;
  /* Black */
  color: #212121;
}

/**
*
*Inicio de las validaciones de para ocultar  los card solo estan vacios
*
**/

.card-none-detail {
}

.card-none-detail2 {
}

.grid-card {
  justify-content: center;
}

.content-pay1 {
}

.content-pay2 {
}

.content-attendance-mobile {
  text-align: center !important;
}

/* Pantalla grande */
@media only screen and (min-width: 1440px) and (max-width: 10000px) {
  .content-insurance {
    flex-direction: column;
    padding: 15px 0px 0px 250px;
    height: auto !important;
    width: auto !important;
    background-color: #f4f4f4 !important;
  }
  .card-complete {
    width: 797px;
  }

  .card-none-detail2 {
    display: none !important;
  }

  .card-detail-plan {
    width: 315px !important;
    height: auto;
  }

  .content-pay1 {
    display: none !important;
  }
  .content-attendance-mobile {
    display: none !important;
  }
  .card-content-attendance2 {
    display: none !important;
  }
}

/* Escritorio grande */
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .content-insurance {
    flex-direction: column;
    padding: 15px 0px 0px 50px;
    height: auto !important;
    width: auto !important;
    background-color: #f4f4f4 !important;
  }
  .card-complete {
    /*width: 637px!important;*/
    width: 727px !important;
    /* width: 797px!important;*/
  }

  .card-none-detail2 {
    display: none !important;
  }

  .card-detail-plan {
    width: 291px !important;
    height: auto;
  }
  .content-pay1 {
    display: none !important;
  }
  .content-attendance-mobile {
    display: none !important;
  }
  .card-content-attendance2 {
    display: none !important;
  }
}

/* Laptop */
@media only screen and (min-width: 821px) and (max-width: 1024px) {
  .content-insurance {
    height: auto !important;
    width: 100% !important;
    background-color: #f4f4f4 !important;
  }
  .card-complete {
    width: 550px !important;
    height: auto;
    margin-bottom: 5%;
    margin-left: 40px;
    /* White */
    background: white;
    border-radius: 8px;
    padding-bottom: 3%;
  }

  .card-none-detail2 {
    display: none !important;
  }

  .card-detail-plan {
    width: 291px !important;
    background-color: #ffffff !important;
    height: auto;
  }

  .title-detail {
    font-size: 16px !important;
  }

  .title-detail-text {
    font-size: 12px !important;
  }
  .title-detail-description {
    font-size: 16px !important;
  }
  .grid-card {
    justify-content: left !important;
  }
  .content-pay1 {
    display: none !important;
  }
  .content-attendance-mobile {
    display: none !important;
  }

  .card-tow-column {
    height: 100%;
    align-self: center !important;
    justify-content: center !important;
  }

  .card-tow-column-blue {
    height: auto !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .card-content-attendance2 {
    display: none !important;
  }
}

/* Tablets lager */
@media only screen and (min-width: 801px) and (max-width: 821px) {
  .card-complete {
    width: auto !important;
  }

  .card-none-detail2 {
    display: none !important;
  }

  .card-detail-plan {
    width: 509px !important;
    background-color: #fff !important;
    align-items: flex-end !important;
    height: auto;
  }

  .grid-card {
    justify-content: left !important;
  }

  .content-pay1 {
    display: none !important;
  }

  .title-detail {
    font-size: 16px !important;
  }

  .title-detail-text {
    font-size: 12px !important;
  }
  .title-detail-description {
    font-size: 16px !important;
  }
  .content-attendance-mobile {
    display: none !important;
  }

  .card-tow-column {
    height: 100%;
    align-self: center !important;
    justify-content: center !important;
  }

  .card-tow-column-blue {
    height: auto !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .card-content-attendance2 {
    display: none !important;
  }
}

/* Tablets y phablets */
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .card-complete {
    width: auto !important;
  }

  .card-detail-plan {
    width: 509px !important;
    background-color: #fff !important;
    justify-content: center !important;
    height: auto;
  }

  .card-none-detail2 {
    display: none !important;
  }

  .grid-card {
    justify-content: center;
  }

  .content-pay1 {
    display: none !important;
  }

  .title-detail {
    font-size: 16px !important;
  }

  .title-detail-text {
    font-size: 12px !important;
  }
  .title-detail-description {
    font-size: 16px !important;
  }
  .card-gray {
    background-color: #fafbfc;
  }
  .card-blue {
    background-color: #f2fafc;
  }

  .card-tow-column {
    height: auto !important;
    justify-content: center !important;
    text-align: center !important;
    width: auto !important;
    background-color: RGB(89, 89, 89, 0.06);
  }

  .card-tow-column-blue {
    height: auto !important;
    justify-content: center !important;
    width: 90% !important;
    background-color: #c0e6f2;
  }

  .content-attendance-lap-table {
    display: none !important;
  }
  .card-content-attendance2 {
    display: none !important;
  }
}

/* Teléfonos */
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .card-complete {
    width: auto !important;
  }

  .card-none-detail {
    display: none !important;
  }

  .card-detail-plan {
    width: auto !important;
    height: auto;
  }

  .grid-card {
    justify-content: center;
  }

  .content-pay2 {
    display: none !important;
  }

  .title-detail {
    font-size: 16px !important;
  }

  .title-detail-text {
    font-size: 12px !important;
  }
  .title-detail-description {
    font-size: 16px !important;
  }

  .card-gray {
    width: 348px !important;
    background-color: #fafbfc;
  }
  .card-blue {
    background-color: #f2fafc !important;
  }

  .card-tow-column-blue {
    height: 100%;
    justify-content: center !important;
    width: 90% !important;
    margin-right: 5% !important;
    margin-left: 5% !important;
    margin-bottom: 5%;
    background-color: #c0e6f2;
  }

  .card-tow-column {
    height: 100%;
    justify-content: center !important;
    width: 90% !important;
    margin-right: 5% !important;
    margin-left: 5% !important;
    margin-bottom: 5%;
    background-color: RGB(89, 89, 89, 0.06);
  }

  .card-content-attendance {
    display: none !important;
  }

  .text-attendance-title {
    /* Heading 7 */
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    margin-left: 0% !important;
    color: #212121;
  }

  .text-attendance-title2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    margin-left: 0% !important;
    /* Black */
    color: #212121;
  }

  .content-attendance-lap-table {
    display: none !important;
  }

  .content-attendance {
    padding-top: 5% !important;
    width: auto !important;
    margin-bottom: 0% !important;
  }
}

.title-detail {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  color: #0a3949;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
}

.title-detail-description {
  /* Heading 7 */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  text-align: right;
  color: #0a3949;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.title-detail-text {
  /* Body 6 */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #0a3949;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
}
.title-accidents {
  width: 205px;
  height: 24px;
  left: 31px;
  top: 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* Black */
  color: #212121;
}

.description-accidents {
  width: 477px;
  height: 15px;
  left: 31px;
  top: 79px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #212121;
}

/**
*
*Fin de las validaciones de para ocultar  los card
*
**/

.column {
  float: left;
  width: 33.33%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.title-pago {
  height: 24px;
  font-family: "Montserrat";
  font-style: normal;
  text-align: left;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  /* Black */

  color: #212121;
}

.title-option {
  /* Option */
  height: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */
  /* Black */
  color: #212121;
}
