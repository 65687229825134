/*footer*/
.footer {
  padding-top: 1rem;
  background: #212121;
  width: 100%;
}

.header-footer {
  font-weight: 700;
  font-size: 14px;
  color: #fff;
}

.body-footer {
  font-weight: 200;
  font-size: 14px;
  color: #fff;
}

.a {
  text-decoration: none;
}

.logos {
  display: inline-flex;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}
.logos-footer {
  display: inline-flex;
}
.footer-footer {
  font-weight: 200;
  font-size: 10px;
  color: #fff;
}

/*header*/
.header {
  margin: 17px 0px 0px 15px;
  background: #ffffff;
  box-shadow: 4px 6px 16px rgba(33, 33, 33, 0.05);
  width: 100%;
}

.text-header {
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: right;
  width: 90px;

  color: #212121;
}

.text-header-pregunta {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: right;
  color: #212121;
}

.MuiMenuItem-root:hover {
  background-color: #039ecc !important;
}

.item-menu {
  font-family: "Montserrat";
  font-size: 16px;

  color: #212121;
}

.textMovil {
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  color: #212121;
}

.collapse-list {
  background: #f4f4f4;
}

.modal-individual-padding {
  padding: 20px 24px;
}

.pb-1rem {
  padding-bottom: 1rem;
}

.relative {
  position: relative;
}

.bg-footer {
  background-color: #282828;
}

@media (max-width: 1366px) {
  .container-lg {
    display: none;
  }
}

.header-footer {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
}

.body-footer {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
}

.logos {
  display: inline-flex;
}

/* .logos > div:first-child {
  padding-right: 15px;
} */

.logos-footer {
  display: inline-flex;
}

/* .logos-footer > a {
  padding-right: 20px;
} */

.logos-footer > a:last-child {
  padding-right: 5px;
}

.footer-footer {
  font-weight: 200;
  font-size: 10px;
  color: #fff;
}

@media only screen and (((min-width: 360px) and (max-width: 780px)) or ((min-width: 375px) and (max-width: 812px)) or ((min-width: 390px) and (max-width: 844px))) and (orientation:landscape){
  .position-Flex{
      flex-direction: row !important;
  }

  .paddingLogo{
    padding-left: 16px !important;
  }

  .paddingProducts{
    padding-left: 0px !important;
  }

  .paddingClients{
    padding-left: 0px !important;
    padding-bottom: 0px !important;
  }

  .paddingDownOApp{
    padding-top:40px !important;
    padding-bottom:40px !important;
    padding-left: 0px !important;
  }

  .paddingAppleG{
    padding-bottom: 16px !important
  }

  .paddingContactUs{
    padding-top: 24px !important;
    padding-left: 0px !important;
    padding-right: 16px !important;
  }

  .paddingFollowUs{
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    padding-left: 0px !important;
    padding-right: 16px !important;
  }

  .paddingCopyright{
    padding-top: 0px !important;
    text-align: center;
  }
}

@media only screen and (min-width: 320px) and (max-width: 568px) and (orientation:landscape) {
  .position-Flex{
    flex-direction: column !important;
  }

  .paddingProducts{
    padding-left: 24px !important;
  }

  .paddingClients{
    padding-left: 24px !important;
    padding-bottom: 0px !important;
  }

  .paddingDownOApp{
    padding-top:24px !important;
    padding-bottom: 0px !important;
    padding-left: 24px !important;
  }

  .paddingAppleG{
    padding-bottom: 24px !important
  }

  .paddingContactUs{
    padding-top: 24px !important;
    padding-left: 24px !important;
  }

  .paddingFollowUs{
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    padding-left: 24px !important;
  }

  .paddingCopyright{
    padding-top: 0px !important;
    text-align: start;
  }

  .pbSocialNet{
    padding-bottom: 24px !important;    
  }
}