.title-accord-pay{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    /* identical to box height */
    /* Black */
    color: #0A3949;
}
.subtitle-accord-pay{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    /* Black */
    color: #0A3949;
}

.size-img{
    width: 127px;
    height: 127px;
    object-fit:cover;
    border-radius: 127px;
}

/* pantalla grande  */
@media only screen and (min-width: 1440px) and (max-width: 10000px) {
    .size-img{
        width: 234.5px;
        height: 234.5px;
        border-radius: 234.5px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px){
    .size-img{
        width: 234.5px;
        height: 234.5px;
        border-radius: 234.5px;
    }
}
/* Laptop */
@media only screen and (min-width: 821px) and (max-width: 1024px){
    .size-img{
        width: 234.5px;
        height: 234.5px;
        border-radius: 234.5px;
    }
}





