.modal-content {
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 10px;
  outline: none;
  position: absolute;
  width: 453px;
  height: 591px;
  left: 493px;
  top: 179px;
  /* White */
  background: #ffffff;
  /* Shadow-Card */
  border-radius: 8px;
}

.content-plan {
  box-sizing: border-box;
  /* Auto layout */
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin: 10px;
  gap: 10px;
  width: auto !important;
  height: 125px;
  background: #ffffff;
  /* Black 25% */
  border: 1px solid #c7c7c7;
  border-radius: 10px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.card-plans-toolbar {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  isolation: isolate;
  width: auto !important;
  height: 50px;
  /* Azul Inter */
  /* Black 25% */
  border: 0px solid #c7c7c7;
  border-radius: 10px 10px 0px 0px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.check-select {
  position: absolute;
  width: 35px;
  height: 35px;
  align-items: center !important;
  text-align: center !important;
  justify-content: center;
  border-radius: 36px;
  left: 85%;
  padding: 1px;
  margin-top: -40px;
  /* Green */
  background: #5ab52f;
}

/* pantalla grande  */
@media only screen and (min-width: 1441px) and (max-width: 10000px) {
  .modal-content {
    position: absolute;
    width: auto !important;
    height: auto !important;
    left: 38%;
    right: 38%;
    top: 21% !important;
  }
  .content-plan {
  }
  .card-plans-toolbar {
  }

  .check-select {
    left: 89% !important;
  }
}

/* Escritorio grande */
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .modal-content {
    position: absolute;
    width: auto !important;
    height: auto !important;
    left: 35%;
    right: 35%;
    top: 15% !important;
  }

  .check-select {
    left: 86% !important;
  }
}

/* Laptop */
@media only screen and (min-width: 821px) and (max-width: 1024px) {
  .modal-content {
    position: absolute;
    width: auto !important;
    height: auto !important;
    left: 30%;
    right: 30%;
    top: 10% !important;
  }
  .check-select {
    left: 85% !important;
  }
}

/* Tablets lager */
@media only screen and (min-width: 801px) and (max-width: 820px) {
  .modal-content {
    position: absolute;
    width: auto !important;
    height: auto !important;
    left: 25%;
    right: 25%;
    top: 15% !important;
  }

  .check-select {
    left: 83% !important;
    margin-top: -25px !important;
  }
}

/* Tablets y phablets */
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .modal-content {
    position: absolute;
    width: auto !important;
    height: auto !important;
    left: 25%;
    right: 25%;
    top: 15% !important;
  }

  .check-select {
    left: 83% !important;
    margin-top: -25px !important;
  }
}

/* Teléfonos  LL */
@media only screen and (min-width: 426px) and (max-width: 767px) {
  .modal-content {
    position: absolute !important;
    width: 300px !important;
    height: 510px !important;
    left: 20% !important;
    right: 20% !important;
    top: 12% !important;
  }
  .check-select {
    left: 85% !important;
  }
}

/* Teléfonos  L */
@media only screen and (min-width: 376px) and (max-width: 425px) {
  .modal-content {
    position: absolute !important;
    width: auto !important;
    height: 510px !important;
    left: 5% !important;
    right: 5% !important;
    top: 12% !important;
  }
  .check-select {
    left: 86%;
  }
}

/* Teléfonos  M */
@media only screen and (min-width: 321px) and (max-width: 375px) {
  .modal-content {
    position: absolute !important;
    width: 310px !important;
    height: 510px !important;
    left: 0px !important;
    top: 12% !important;
  }
  .check-select {
    left: 84%;
  }
}

/* Teléfonos  S */
@media only screen and (min-width: 0px) and (max-width: 320px) {
  .modal-content {
    position: absolute;
    align-self: center !important;
    width: 260px !important;
    height: 550px !important;
    left: 0px !important;
    top: 12% !important;
  }
  .check-select {
    left: 82%;
  }
}
